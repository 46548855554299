import { styled } from '@mui/system';

import PaperBlock from 'ReactKitComponents/Atoms/PaperBlock/PaperBlock';
import CustomTextField from 'ReactKitComponents/Atoms/InputComponents/TextField/TextField';
import RaisedButton from 'ReactKitComponents/Atoms/Buttons/StandardButtons/RaisedButton';
import CustomTypography from 'ReactKitComponents/Atoms/Typography/CustomTypography';
import IconButton from 'ReactKitComponents/Atoms/Buttons/StandardButtons/IconButton';
import Link from 'ReactKitComponents/Atoms/Link/Link';
import CustomAlert from 'ReactKitComponents/Atoms/Alerts/CustomAlert';

export const Wrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  'p:not(:last-child)': {
    color: 'red ',
  },
}));

export const StyledPaper = styled(PaperBlock)(({ theme }) => ({
  maxWidth: '600px',
  backgroundColor: '#F7F2E9',
  width: '599px',
  padding: '56px 123px',
  height: '583px',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  '@media (max-width:600px)': {
    padding: theme.spacing(7, 1),
  },
  '& .title': {
    fontSize: '24px',
    lineHeight: '22px',
    fontWeight: 500,
    marginBottom: '8px',
  },
}));

export const StyledForm = styled('form')(() => ({
  display: 'flex',
  flexDirection: 'column',
  p: {
    textAlign: 'center',
  },
}));

export const StyledInput = styled(CustomTextField)(({ theme }) => ({
  boxSizing: 'border-box',
  backgroundColor: '#ffffff',
  borderRadius: '8px',
  marginTop: '60px',
  height: 'auto',
  fieldset: {
    border: '1px solid #BDBDBD',
  },
  width: '100%',
  '@media (max-width:350px)': {
    minWidth: '250px',
  },
  '& .MuiFormHelperText-root': {
    ...theme.typography.body2,
  },
}));

export const StyledButton = styled(RaisedButton)(() => ({
  width: '111px',
  height: '40px',
  borderRadius: '50px',
  fontSize: '18px',
  lineHeight: '25.92px',
  fontWeight: 400,
  backgroundColor: '#FEB80A',
  color: '#000000',
  boxShadow: 'none',
  padding: '8px 32px',
  cursor: 'pointer',
  ':hover': {
    backgroundColor: '#FEB80A',
    border: 'none',
  },
}));

export const Title = styled(CustomTypography)(({ theme, ...props }) => ({
  marginBottom: props.alert ? theme.spacing(4) : theme.spacing(8),
}));

export const HelperText = styled(CustomTypography)(({ theme, ...props }) => ({
  marginBottom: theme.spacing(4),
  maxWidth: '300px',
  width: '100%',
  paddingLeft: theme.spacing(1),
  ...theme.typography.body2,
  color: props.error ? theme.palette.error.main : theme.visualization.grey,
  '@media (max-width:350px)': {
    maxWidth: '250px',
  },
}));

export const StyledIcon = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(0),
}));

export const StyledText = styled(CustomTypography)(({ theme }) => ({
  marginTop: theme.spacing(3),
  ...theme.typography.body2,
  color: theme.visualization.grey,
}));

export const SignUp = styled(Link)(() => ({
  fontSize: '16px',
  lineHeight: '20px',
  fontWeight: 400,
  color: '#000000',
  textDecoration: 'underline',
  display: 'inline-block',
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'right',
  justifyContent: 'right',
  width: '100%',
  position: 'relative',
  top: `-${theme.spacing(4)}`,
  '@media (max-width:600px)': {
    top: `-${theme.spacing(4)}`,
  },
}));

export const Alert = styled(CustomAlert)(({ theme }) => ({
  width: '300px',
  boxSizing: 'border-box',
  '@media (max-width:350px)': {
    maxWidth: '250px',
  },
  marginBottom: theme.spacing(2),
  color: '#fff',
}));
