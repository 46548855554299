import React, { useState } from 'react';

import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import AlertSeverityType from 'ReactKitComponents/Enum/AlertSeverityType';
import { Wrapper, StyledLogin } from './Styles';
import { useAuth } from '../../Contexts/AuthContext';

/**
 * @returns Login form with email , password field
 */
const Login = () => {
  const { login } = useAuth();
  const history = useHistory();
  const [error, setError] = useState(null);

  const forgetPassword = {
    to: '/reset-password',
    label: 'Forget Password?',
  };
  const redirectProps = {
    description: 'Dont have an account? ',
    label: 'Create an account',
    to: '/signup',
  };
  const alertProps = {
    severity: AlertSeverityType.error,
    message: error,
  };
  const loginSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
  });

  /** on submit, fires firebase login & navigates back */
  const submitHandler = async (e) => {
    try {
      setError(null);
      await login(e.email, e.password);
      history.push('/');
    } catch (err) {
      setError(err);
    }
  };

  return (
    <Wrapper>
      <StyledLogin
        submitHandler={submitHandler}
        title='Login'
        subTitle='Let’s continue with your generous mission.'
        submitLabel='Login'
        forgetPasswordProps={forgetPassword}
        validationSchema={loginSchema}
        redirectProps={redirectProps}
        alertProps={error && alertProps}
      />
    </Wrapper>
  );
};

export default Login;
