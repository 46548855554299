const typography = {
  useNextVariants: true,
  fontFamily: 'roc-grotesk',
  // Page Heading
  h1: {
    fontWeight: 400,
    fontSize: '6rem',
    lineHeight: '90px',
    '@media (max-width:900px)': {
      fontSize: '6rem',
      lineHeight: '90px',
    },
    '@media (max-width:768px)': {
      fontSize: '6rem',
      lineHeight: '90px',
    },
  },
  h2: {
    fontWeight: 400,
    fontSize: '4.5rem',
    lineHeight: '80px',
    '@media (max-width:1280px)': {
      fontSize: '4.5rem',
      lineHeight: '80px',
    },
    '@media (max-width:768px)': {
      fontSize: '4.5rem',
      lineHeight: '80px',
    },
  },
  // Section Heading
  h3: {
    fontWeight: 400,
    fontSize: '3rem',
    lineHeight: '70px',
    '@media (max-width:1280px)': {
      fontSize: '4rem',
      lineHeight: '70px',
    },
    '@media (max-width:768px)': {
      fontSize: '4rem',
      lineHeight: '70px',
    },
  },
  h4: {
    fontWeight: 300,
    fontSize: '3rem',
    lineHeight: '52px',
    '@media (max-width:1280px)': {
      fontSize: '3rem',
      lineHeight: '52px',
    },
    '@media (max-width:768px)': {
      fontSize: '3rem',
      lineHeight: '52px',
    },
  },
  h5: {
    fontWeight: 400,
    fontSize: '2rem',
    lineHeight: '36px',
    '@media (max-width:1280px)': {
      fontSize: '2rem',
      lineHeight: '36px',
    },
    '@media (max-width:768px)': {
      fontSize: '2rem',
      lineHeight: '36px',
    },
  },
  // Card Title
  h6: {
    fontSize: '1.5rem',
    lineHeight: '36px',
    fontWeight: 400,
    '@media (max-width:1280px)': {
      lineHeight: '28px',
      fontWeight: 400,
    },
    '@media (max-width:768px)': {
      lineHeight: '28px',
      fontWeight: 400,
    },
  },
  subtitle1: {
    fontSize: '1rem',
    lineHeight: '24px',
    fontWeight: 700,
    '@media (max-width:1280px)': {
      fontSize: '1rem',
      lineHeight: '24px',
    },
    '@media (max-width:768px)': {
      fontSize: '1rem',
      lineHeight: '24px',
    },
  },
  subtitle2: {
    fontSize: '0.875rem',
    lineHeight: '20px',
    fontWeight: 400,
    '@media (max-width:1280px)': {
      fontSize: '0.875rem',
      lineHeight: '20px',
    },
    '@media (max-width:768px)': {
      fontSize: '0.875rem',
      lineHeight: '20px',
    },
  },
  body1: {
    fontWeight: 400,
    fontSize: '1.125rem',
    lineHeight: '22px',
    '@media (max-width:1280px)': {
      fontSize: '1.125rem',
      lineHeight: '22px',
    },
    '@media (max-width:768px)': {
      fontSize: '1.125rem',
      lineHeight: '22px',
    },
  },
  body2: {
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '23.04px',
    '@media (max-width:1280px)': {
      fontSize: '1rem',
      lineHeight: '23.04px',
    },
    '@media (max-width:768px)': {
      fontSize: '1rem',
      lineHeight: '23.04px',
    },
  },
  button: {
    fontWeight: 400,
    fontSize: '1.5rem',
    lineHeight: '34.56px',
    '@media (max-width:1280px)': {
      fontSize: '1.5rem',
      lineHeight: '34.56px',
    },
    '@media (max-width:768px)': {
      fontSize: '1.5rem',
      lineHeight: '34.56px',
    },
  },
  caption: {
    fontWeight: 500,
    fontSize: '1.125rem',
    lineHeight: '24.59px',
    '@media (max-width:1280px)': {
      fontSize: '1.125rem',
      lineHeight: '24.59px',
    },
    '@media (max-width:768px)': {
      fontSize: '1.125rem',
      lineHeight: '24.59px',
    },
  },
  overline: {},
};

export default typography;
