import { styled } from '@mui/system';
import TablePagination from '@mui/material/TablePagination';

const StyledTablePagination = styled(TablePagination)(({ theme }) => ({
  '& .MuiTablePagination-selectLabel': {
    ...theme.typography.caption,
  },
  '& .MuiSvgIcon-root': {
    width: '12px',
  },
  '& .MuiTablePagination-displayedRows': {
    ...theme.typography.caption,
    color: theme.visualization.blue,
  },
  '& .MuiInputBase-input': {
    ...theme.typography.caption,
    minWidth: 'none',
  },
  '& .MuiInputBase-root': {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(3),
  },
  '& .MuiToolbar-root': {
    marginRight: theme.spacing(3),
  },
}));

export default StyledTablePagination;
