import { configureStore } from '@reduxjs/toolkit';

import authSlice from './Slices/Auth';
import userSlice from './Slices/User';

const store = configureStore({
  reducer: { auth: authSlice.reducer, users: userSlice.reducer },
});

export default store;
