import React from 'react';

import BaseLayout from 'Components/Layout/BaseLayout';
import ThankContainer from 'Containers/Thankyou/ThankContainer';
import Greeting from './Components/Greeting';
import {
  BannerImg, Wrapper, BannerWrapper, Container,
} from './style';

const ThankYou = () => (
  <BannerImg>
    <BaseLayout>
      <Wrapper>
        <Greeting />
      </Wrapper>
      <Container>
        <BannerWrapper>
          <ThankContainer />
        </BannerWrapper>
      </Container>
    </BaseLayout>
  </BannerImg>
);

export default ThankYou;
