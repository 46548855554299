import React, { useState } from 'react';

import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import justGiving from 'assets/Images/BannerImages/justGiving.png';
import Modal from 'ReactKitComponents/Atoms/Modals/Modal';
import { useAuth } from 'Contexts/AuthContext';
import { RedirectPassword } from 'Resources/Strings';
import CustomCircularProgress from 'ReactKitComponents/Atoms/ProgressBar/CircularProgressBar/CircularProgress';
import CustomLink from 'ReactKitComponents/Atoms/Link/Link';
import CustomTypography from 'ReactKitComponents/Atoms/Typography/CustomTypography';
import {
  StyledPaper,
  StyledForm,
  StyledInput,
  HelperText,
  StyledButton,
} from './Style';

/**
 * @param {handleModal} props - func to handle the modal state
 * @returns a modal of form with email field
 */
const AuthDialog = (props) => {
  const [open, setopen] = useState(true);
  const [error, seterror] = useState(null);
  const [loading, setLoading] = useState(false);
  const { signup, login } = useAuth();
  const { handleModal, link } = props;

  const handleClose = () => {
    handleModal();
    setopen(false);
  };

  /** on submit, will fire firebase signup & navigates back */
  const SubmitHandler = async (e) => {
    setLoading(true);
    seterror(null);
    try {
      await signup(e.email, RedirectPassword);
      await window.open(link, '_self');
      setLoading(false);
      handleClose();
    } catch (err) {
      seterror(err);
      setLoading(false);
    }
  };

  const LoginHandler = async (e) => {
    setLoading(true);
    seterror(null);
    try {
      await login(e.email, e.password);
      await window.open(link, '_self');
      setLoading(false);
      handleClose();
    } catch (err) {
      seterror(err);
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: { email: '' },
    validationSchema: Yup.object().shape({
      email: Yup.string().email('Invalid email').required('Required'),
    }),
    onSubmit: !error
      ? (values) => SubmitHandler(values)
      : (values) => LoginHandler(values),
  });

  return (
    <Modal open={open} onClose={handleClose}>
      <StyledPaper error={error}>
        <div>
          <CustomTypography className='great'>
            {error ? 'Login' : 'Great choice!'}
          </CustomTypography>
          <CustomTypography className='desc'>
            {error
              ? 'Let’s continue with your generous mission.'
              : 'Your generous donation is processed by Just Giving. Please provide us with your email to proceed.'}
          </CustomTypography>
          <StyledForm onSubmit={formik.handleSubmit}>
            <StyledInput
              id='email'
              name='email'
              type='email'
              onChange={formik.handleChange}
              value={formik.values.email}
              label='Email'
            />
            <HelperText>{formik.errors.email}</HelperText>
            {error ? (
              <StyledInput
                id='password'
                name='password'
                type='password'
                onChange={formik.handleChange}
                value={formik.values.password}
                label='password'
              />
            ) : (
              ''
            )}
            <HelperText className='error' error={error}>
              {error}
            </HelperText>
            <div className='wrapper'>
              {' '}
              {!error ? (
                <StyledButton
                  error={error}
                  className='button'
                  type='submit'
                  disabled={!(formik.isValid && formik.dirty) || loading}
                >
                  {loading ? <CustomCircularProgress size={20} /> : 'Proceed'}
                </StyledButton>
              ) : (
                <StyledButton
                  error={error}
                  className='button'
                  type='submit'
                  disabled={!(formik.isValid && formik.dirty) || loading}
                >
                  {loading ? <CustomCircularProgress size={20} /> : 'Login'}
                </StyledButton>
              )}
              {error ? (
                <CustomLink className='forget' to='/reset-password'>
                  Forget Password?
                </CustomLink>
              ) : (
                ''
              )}
            </div>
          </StyledForm>
        </div>
        {error ? (
          ''
        ) : (
          <div>
            <CustomTypography className='powered'>powered by</CustomTypography>
            <img src={justGiving} alt='justgivingLogo' />
          </div>
        )}
      </StyledPaper>
    </Modal>
  );
};

export default AuthDialog;

AuthDialog.propTypes = {
  handleModal: PropTypes.func.isRequired,
  link: PropTypes.string.isRequired,
};
