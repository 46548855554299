import React, { useState, useEffect } from 'react';

import refugeeData from 'Resources/Data/RefugeeDetail';
import AmountDonate from 'Pages/Landing/Components/DonationImpact/AmountDonate';

const RefugeeDetailContainer = () => {
  const [data, setData] = useState();
  useEffect(() => {
    const getData = refugeeData;
    setData(getData);
  }, []);
  return <AmountDonate data={data} />;
};

export default RefugeeDetailContainer;
