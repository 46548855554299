import React from 'react';

import PropTypes from 'prop-types';

const CustomComponent = React.forwardRef((props, ref) => {
  const { children } = props;
  return (
    <span {...props} ref={ref}>
      {children}
    </span>
  );
});

CustomComponent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CustomComponent;
