import React from 'react';

import { useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';

import { useAuth } from 'Contexts/AuthContext';
import headerLogo from 'assets/Images/BannerImages/headerLogo.png';
import headerLight from 'assets/Images/BannerImages/headerLight.png';
import CustomLink from 'ReactKitComponents/Atoms/Link/Link';
import {
  logIn, donate, logOutText, myAccount,
} from 'Resources/Strings';
import {
  StyledHeader,
  LogoDiv,
  LogInDiv,
  LogInButton,
  DonateButton,
} from './Styles';

const Header = (props) => {
  const { bg } = props;
  const { currentUser, logout } = useAuth();
  const history = useHistory();
  return (
    <>
      <StyledHeader bg={bg}>
        <CustomLink to='/'>
          <LogoDiv>
            <img src={!bg ? headerLogo : headerLight} alt='logo' />
          </LogoDiv>
        </CustomLink>
        <LogInDiv>
          {currentUser ? (
            <>
              <LogInButton
                className='button'
                bg={bg}
                onClick={() => history.push('/my-donations')}
              >
                {myAccount}
              </LogInButton>
              <DonateButton className='button' bg={bg} onClick={logout}>
                {logOutText}
              </DonateButton>
            </>
          ) : (
            <>
              <LogInButton
                className='button'
                bg={bg}
                onClick={() => history.push('/login')}
              >
                {logIn}
              </LogInButton>
              <DonateButton
                className='button'
                bg={bg}
                onClick={() => history.push('/charities')}
              >
                {donate}
              </DonateButton>
            </>
          )}
        </LogInDiv>
      </StyledHeader>
    </>
  );
};

export default Header;

Header.propTypes = {
  bg: PropTypes.bool,
};
