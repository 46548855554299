import React from 'react';

import { useHistory } from 'react-router-dom';

import TimesNewlogo from 'assets/Images/BannerImages/timesnew.png';
import GoogleLogo from 'assets/Images/BannerImages/google.png';
import BloomberLogo from 'assets/Images/BannerImages/bloomberg.png';
import {
  standWithUkraine,
  helpDescription,
  donateNow,
} from 'Resources/Strings';
import {
  NewsWrapper,
  StyledNewsGrid,
  NewsCardGrid,
  NewsTextGrid,
  LetUsStandText,
  InfoText,
  NewsDonateButton,
  CivilianNoTypography,
  DescriptionTypography,
  StyledPaper,
} from './style';

const NewsInfo = () => {
  const history = useHistory();

  return (
    <NewsWrapper>
      <StyledNewsGrid container justifyContent='space-between'>
        <NewsCardGrid item xs={12} sm={5} md={5}>
          <StyledPaper className='civilian'>
            <img className='image' src={TimesNewlogo} alt='news logo' />
            <CivilianNoTypography className='number'>
              2,685
            </CivilianNoTypography>
            <DescriptionTypography className='description'>
              civilian casualties since Feb 24, 2022
            </DescriptionTypography>
          </StyledPaper>
          <StyledPaper className='million'>
            <img className='image' src={GoogleLogo} alt='news logo' />
            <CivilianNoTypography className='number'>
              More than 3.9 million
            </CivilianNoTypography>
            <DescriptionTypography className='description'>
              refugees evacuated since Feb 24, 2022
            </DescriptionTypography>
          </StyledPaper>
          <StyledPaper className='billion'>
            <img className='image' src={BloomberLogo} alt='news logo' />
            <CivilianNoTypography className='number'>
              More than 8 billion
            </CivilianNoTypography>
            <DescriptionTypography className='description'>
              US assistance sent to Ukraine over the past year
            </DescriptionTypography>
          </StyledPaper>
        </NewsCardGrid>

        <NewsTextGrid item xs={12} sm={5} md={6}>
          <LetUsStandText className='letUs'>{standWithUkraine}</LetUsStandText>
          <InfoText className='helpDesc'>{helpDescription}</InfoText>
          <NewsDonateButton
            className='donateButton'
            onClick={() => history.push('/charities')}
          >
            {donateNow}
          </NewsDonateButton>
        </NewsTextGrid>
      </StyledNewsGrid>
    </NewsWrapper>
  );
};

export default NewsInfo;
