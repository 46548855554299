import React from 'react';

import PropTypes from 'prop-types';

import Header from 'Components/Header/Header';
import Footer from 'Components/Footer/Footer';
import {
  StyledMain,
  BodyWrapper,
} from './Styles';

/** [BaseLayout]
 * @constructor
 * @param  {node} children - pass the children for main section
 */

const BaseLayout = (props) => {
  const { children, bg } = props;
  return (
    <div>
      <Header bg={bg} />
      <BodyWrapper>
        <StyledMain>{children}</StyledMain>
      </BodyWrapper>
      <Footer />
    </div>
  );
};

BaseLayout.propTypes = {
  children: PropTypes.node.isRequired,
  bg: PropTypes.bool,
};

export default BaseLayout;
