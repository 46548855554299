import { styled } from '@mui/system';

import CustomTypography from 'ReactKitComponents/Atoms/Typography/CustomTypography';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';

export const TableTitle = styled(CustomTypography)(() => ({
  flex: '1 1 100%',
  textAlign: 'left',
}));

export const TableCellBody = styled(TableCell)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.visualization.grey,
}));

export const TableCellHead = styled(TableCell)(({ theme }) => ({
  ...theme.typography.h6,
  fontWeight: theme.typography.body1.fontWeight,
  color: theme.background.black,
}));

export const StyledTable = styled(Table)(() => ({
  minWidth: 650,
}));

export const StyledTableContainer = styled(TableContainer)(({ theme, ...props }) => ({
  backgroundColor: props.backgroundColor
    ? props.backgroundColor
    : theme.background.white,
}));
