import React from 'react';

import PropTypes from 'prop-types';

import CustomAvatar from 'ReactKitComponents/Atoms/Avatars/CustomAvatar/CustomAvatar';

import StyledListItemAvatar from './Style';
/**
 * @param  {} avatarComponent - children for the Avatar component, if no avatarImage passed
 * @param  {} avatarImage - avatar image (no need to pass aatarComponent if avatarImage is provided)
 * @param  {} avatarImageAlt - alt for the image
 */
const CustomListItemAvatar = (props) => {
  const {
    avatarComponent,
    avatarImage,
    avatarImageAlt,
    avatarVariant,
    sx,
    classes,
    ...rest
  } = props;

  return (
    <StyledListItemAvatar>
      <CustomAvatar
        src={avatarImage}
        alt={avatarImageAlt}
        variant={avatarVariant}
        sx={sx}
        classes={classes}
        {...rest}
      >
        {avatarComponent}
      </CustomAvatar>
    </StyledListItemAvatar>
  );
};

CustomListItemAvatar.propTypes = {
  avatarComponent: PropTypes.element,
  avatarImage: PropTypes.string,
  avatarImageAlt: PropTypes.string,
  avatarVariant: PropTypes.string,
  sx: PropTypes.shape({}),
  classes: PropTypes.shape({}),
};

export default CustomListItemAvatar;
