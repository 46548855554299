import React from 'react';

import PropTypes from 'prop-types';

import List from '@mui/material/List';

/**
 * @param  {node} children - children components
 * @param  {Object} sx - children components
 * @param  {Object} classes - Override or extend the styles applied to the component.
 * @param  {bool} dense - If true, compact vertical padding designed for
 * keyboard and mouse input is used for the list and list items.
 * @param  {bool} disablePadding - If true, vertical padding is removed from the list.
 * @param  {node} subheader - The content of the subheader, normally ListSubheader.
 */
const CustomList = (props) => {
  const {
    children,
    sx,
    classes,
    dense,
    disablePadding,
    subheader,
    ...rest
  } = props;
  return (
    <List sx={sx} classes={classes} {...rest}>
      {children}
    </List>
  );
};

CustomList.propTypes = {
  children: PropTypes.node.isRequired,
  sx: PropTypes.shape({}),
  classes: PropTypes.shape({}),
  dense: PropTypes.bool,
  disablePadding: PropTypes.bool,
  subheader: PropTypes.node,
};

export default CustomList;
