import React from 'react';

import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

/** [Grid] is a common MUi component that can be used for creating Grid.
  * @constructor
  * @param {bool} container - If true, the component will have the flex container behavior
  * @param {bool} item - If true, the component will have the flex item behavior
  * @param {number,Array,object,string} spacing - Defines the space between the type item component.
  * @param {number,Array,Object} columns - The number of columns.
  * @param {Array,number,Object,string} columnSpacing - Defines the horizontal space
  * between item components
  * @param {Array,number,Object,string} rowSpacing - Defines the vertical space
  * between item components
  * @param {string,Array,Object} direction - Defines the flex-direction style property.
  * @param {string} wrap - Defines the flex-wrap style property.
  * @param {bool} zeroMinWidth - If true, it sets min-width = 0 on the item
  * @param {elementType} component - The component used for the root node
  * @param {number,string,bool} lg - the no of grids the component is going to use in lg breakpoint
  * @param {number,string,bool} xs - the no of grids the component is going to use in xs breakpoint
  * @param {number,string,bool} md - the no of grids the component is going to use in md breakpoint
  * @param {number,string,bool} sm - the no of grids the component is going to use in sm breakpoint
  * @param {number,string,bool} xl - the no of grids the component is going to use in xl breakpoint
  * @param {Object} classes - to Override or extend the styles applied to component
  * @param {string} children - content of the component.
  * @param {Object} sx - allows defining system overrides as well as additional CSS styles.
  */

const CustomGrid = (props) => {
  const {
    container,
    item,
    children,
    columns,
    spacing,
    columnSpacing,
    rowSpacing,
    component,
    lg,
    xs,
    md,
    sm,
    xl,
    direction,
    wrap,
    sx,
    classes,
    zeroMinWidth,
    ...rest
  } = props;
  return (
    <Grid
      container={container}
      item={item}
      xs={xs}
      lg={lg}
      sm={sm}
      xl={xl}
      md={md}
      spacing={spacing}
      direction={direction}
      columns={columns}
      columnSpacing={columnSpacing}
      rowSpacing={rowSpacing}
      wrap={wrap}
      zeroMinWidth={zeroMinWidth}
      component={component}
      classes={classes}
      sx={sx}
      {...rest}
    >
      {children}
    </Grid>
  );
};

CustomGrid.propTypes = {
  container: PropTypes.bool,
  item: PropTypes.bool,
  children: PropTypes.node,
  component: PropTypes.elementType,
  direction: PropTypes.oneOfType([
    PropTypes.oneOf(['column-reverse', 'column', 'row-reverse', 'row']),
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.shape({}),
  ]),
  wrap: PropTypes.oneOf(['nowrap', 'wrap-reverse', 'wrap']),
  columns: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.shape({})]),
  spacing: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
    PropTypes.shape({})]),
  columnSpacing: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
    PropTypes.shape({}),
    PropTypes.string]),
  rowSpacing: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
    PropTypes.array,
    PropTypes.shape({}),
    PropTypes.string,
  ]),
  zeroMinWidth: PropTypes.bool,
  classes: PropTypes.shape({}),
  sx: PropTypes.shape({}),
  xs: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  md: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  lg: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  sm: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  xl: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
};

CustomGrid.defaultProps = {
  columns: 12,
  container: false,
  item: false,
};

export default CustomGrid;
