import Hunger from 'assets/Images/BannerImages/hunger-cause.png';
import Poverty from 'assets/Images/BannerImages/poverty-cause.png';
import Medical from 'assets/Images/BannerImages/medicalaid-cause.png';
import Child from 'assets/Images/BannerImages/childsupport.png';
import Arrow from 'assets/Images/BannerImages/Arrow.png';

const causeData = [
  {
    causeType: 'Disaster Relief',
    causeImage: Hunger,
    causeArrowImg: Arrow,
    causeSupporter: '224,578',
    causeDescription: 'people supports this cause',
  },
  {
    causeType: 'Refugee Shelter',
    causeImage: Poverty,
    causeArrowImg: Arrow,
    causeSupporter: '655,677',
    causeDescription: 'people supports this cause',
  },
  {
    causeType: 'Medical-aid',
    causeImage: Medical,
    causeArrowImg: Arrow,
    causeSupporter: '224,876',
    causeDescription: 'people supports this cause',
  },
  {
    causeType: 'Child Support',
    causeImage: Child,
    causeArrowImg: Arrow,
    causeSupporter: '655,578',
    causeDescription: 'people supports this cause',
  },
];

export default causeData;
