import { styled } from '@mui/system';

import CustomGrid from 'ReactKitComponents/Atoms/Grid/CustomGrid';
import Sergio from 'assets/Images/BannerImages/sergio-kuub.png';
import CustomTypography from 'ReactKitComponents/Atoms/Typography/CustomTypography';
import IconButton from 'ReactKitComponents/Atoms/Buttons/StandardButtons/IconButton';
import OutlinedButton from 'ReactKitComponents/Atoms/Buttons/StandardButtons/OutlinedButton';

export const CauseTypeWrapper = styled('div')(() => ({
  marginTop: '108px',
  backgroundImage: `url(${Sergio})`,
  backgroundSize: '100%',
  backgroundRepeat: 'no-repeat',
  '& .needHelp': {
    fontSize: '64px',
    lineHeight: '70px',
    fontWeight: 500,
    width: '528px',
  },
  '& .helpDesc': {
    fontSize: '20px',
    lineHeight: '27.32px',
    fontWeight: 400,
  },
  '& .type': {
    fontSize: '32px',
    lineHeight: '36px',
    fontWeight: 500,
  },
  '& .support': {
    fontSize: '32px',
    lineHeight: '31px',
    fontWeight: 400,
    textAlign: 'center',
  },
  '& .desc': {
    fontSize: '22px',
    lineHeight: '31px',
    fontWeight: 400,
    textAlign: 'center',
  },
}));

export const UkraineHelpTextGrid = styled(CustomGrid)(() => ({}));

export const CauseHelpDescriptionGrid = styled(CustomGrid)(() => ({}));

export const UkraineHelpText = styled(CustomTypography)(() => ({}));

export const HelpDescriptionText = styled(CustomTypography)(() => ({}));

export const CauseDetailWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  marginTop: '48px',
}));

export const CauseTypeDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '302px',
  marginBottom: '80px',
}));

export const CauseTypeText = styled(CustomTypography)(() => ({
  marginBottom: '8px',
}));

export const ImageContainer = styled('div')(() => ({
  height: '420px',
  width: '304px',
  overflow: 'hidden',
  position: 'relative',
  display: 'inline-block',
  borderBottomLeftRadius: '200px',
  borderBottomRightRadius: '200px',
}));

export const ImageWrapper = styled('div')(({ ...props }) => ({
  backgroundImage: `url(${props.bgImage})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  width: '100%',
  height: '100%',
  ':hover': {
    transform: 'scale(1.05)',
    transition: 'transform .5s ease-in-out',
  },
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  width: '68px',
  height: '68px',
  marginTop: '-75px',
  marginLeft: '200px',
  cursor: 'pointer',
  borderRadius: '50%',
  backgroundColor: '#FEB80A',
  ':hover': {
    backgroundColor: '#FEB80A',
  },
  '& .MuiIcon-root': {
    color: theme.background.black,
    fontSize: '40px',
  },
}));

export const CauseSupporter = styled(CustomTypography)(() => ({
  marginTop: '24px',
}));

export const CauseDescription = styled(CustomTypography)(() => ({}));

export const CauseContainer = styled('div')(() => ({
  width: '100%',
  margin: '0 auto',
  display: 'flex',
  marginBottom: '32px',
}));

export const Container = styled('div')(() => ({
  display: 'flex',
  margin: '0 auto',
  marginTop: '48px',
  overflow: 'scroll',
  '::-webkit-scrollbar': {
    display: 'none',
  },
}));

export const MapContainer = styled('div')(() => ({
  marginRight: '24px',
}));

export const StyledOutline = styled(OutlinedButton)(({ theme }) => ({
  borderRadius: '50px',
  border: '1px solid black',
  ':hover': {
    background: theme.background.black,
    color: theme.background.white,
    border: 'none',
  },
  marginLeft: 0,
}));
