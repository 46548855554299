import React from 'react';

import PropTypes from 'prop-types';

import CustomBox from 'ReactKitComponents/Atoms/Box/CustomBox';

import { StyledCircularFiller, StyledCircularProgress } from './Style';

/** [CircularProgress] is a common component that can be used for a
 * cicular progressbars with different type of variant
 * @constructor
 * @param {number} value - progress value
 * @param {string} color - color of progress bar
 * @param {number} thickness - thickness of progress bar
 * @param {number} size - size of progress bar
 * @param {string} variant - which type of progress bar want to use
 * @param {node} children - The content to be displayed inside the component.
 * @param {object} fillerClass - Override or extend the styles applied to the filler component.
 * @param {object} progressClass - Override or extend the styles applied to the progress component.
 * @param {bool} disableShrink - If true, the shrink animation is disabled.
 * This only works if variant is indeterminate.
 * @param {object} boxConfig - Configurations to be given for Box.
 * @param {object} sx - The system prop that allows defining system
 * overrides as well as additional CSS styles.
 */

const CustomCircularProgress = (props) => {
  const {
    color,
    value,
    size,
    thickness,
    variant,
    children,
    fillerClass,
    progressClass,
    disableShrink,
    boxConfig,
    sx,
    ...rest
  } = props;
  return (
    <CustomBox position='relative' display='inline-flex' {...rest}>
      <StyledCircularFiller
        classes={fillerClass}
        size={size}
        thickness={thickness}
        variant='determinate'
        value={100}
        color={color}
      />
      <StyledCircularProgress
        classes={progressClass}
        size={size}
        thickness={thickness}
        variant={variant}
        value={value}
        color={color}
        disableShrink={disableShrink}
        sx={sx}
        {...rest}
      />
      <CustomBox
        top={boxConfig.top}
        left={boxConfig.left}
        bottom={boxConfig.bottom}
        right={boxConfig.right}
        position={boxConfig.position}
        display={boxConfig.display}
        alignItems={boxConfig.alignItems}
        justifyContent={boxConfig.justifyContent}
      >
        {children}
      </CustomBox>
    </CustomBox>
  );
};

CustomCircularProgress.propTypes = {
  value: PropTypes.number,
  children: PropTypes.node,
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  thickness: PropTypes.number,
  variant: PropTypes.string,
  fillerClass: PropTypes.string,
  disableShrink: PropTypes.bool,
  progressClass: PropTypes.string,
  sx: PropTypes.shape({}),
  classes: PropTypes.shape({}),
  boxConfig: PropTypes.shape({
    top: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    left: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    bottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    right: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    position: PropTypes.string,
    display: PropTypes.string,
    alignItems: PropTypes.string,
    justifyContent: PropTypes.string,
  }),
};

CustomCircularProgress.defaultProps = {
  boxConfig: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  value: 20,
  size: 100,
  thickness: 2,
};

export default CustomCircularProgress;
