import React, { useState, useEffect } from 'react';

import { collection, getDocs } from 'firebase/firestore';

import { useAuth } from 'Contexts/AuthContext';
import { db } from 'firebase';
import Table from 'Pages/MyDonations/Components/Table/Table';
import columns from 'Resources/Data/TransactionsData';
import CircularProgress from 'ReactKitComponents/Atoms/ProgressBar/CircularProgressBar/CircularProgress';
// import { get } from '../../Services/ApiServiceBase';

/** Data orchesting layer of  my Donations table component
 */
const TransactionTableContainer = () => {
  const { currentUser } = useAuth();
  const [loading, setloading] = useState(true);
  const [donationsData, setDonationsData] = useState(null);

  /** fetches the donation documents of current user from firestore */
  const fetchData = async () => {
    const collRef = collection(db, `users/${currentUser.uid}/donations`);
    const querySnapshot = await getDocs(collRef);
    const dBdata = [];
    querySnapshot.forEach((data) => {
      dBdata.push(data.data());
    });
    setDonationsData(dBdata);
    setloading(false);
  };

  /** converts the data to ReactKit table component data source format */
  const getDataSource = () => {
    const data = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < donationsData.length; i++) {
      const itemData = {
        dataIndex: i,
        date: donationsData[i].date,
        id: donationsData[i].id,
        charityName: donationsData[i].charityName,
        amount: donationsData[i].amount,
        status: donationsData[i].status,
        receipt: '',
      };
      data.push(itemData);
    }
    return data;
  };

  useEffect(() => {
    fetchData();
  }, []);

  return loading ? (
    <CircularProgress />
  ) : (
    <Table columns={columns} dataSource={getDataSource()} />
  );
};

export default TransactionTableContainer;
