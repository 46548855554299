import { styled } from '@mui/system';

import Paper from 'ReactKitComponents/Atoms/PaperBlock/PaperBlock';

const Wrapper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: theme.spacing(4, 4.9),
  minHeight: '200px',
}));

export default Wrapper;
