import { styled } from '@mui/system';

import CustomAppbar from 'ReactKitComponents/Atoms/Appbar/Appbar';
import CustomToolbar from 'ReactKitComponents/Atoms/Toolbar/CustomToolbar';
import CustomLink from 'ReactKitComponents/Atoms/Link/Link';
import Profile from 'ReactKitComponents/Molecules/Profile/Profile';

export const StyledDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.primary.contrastText,
  position: 'sticky',
  top: 0,
  zIndex: 1,
}));

export const StyledAppbar = styled(CustomAppbar)(({ theme, ...props }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'stretch',
  justifyContent: 'center',
  boxShadow: theme.boxShadow.primary,
  minHeight: '72px',
  backgroundColor: theme.palette.primary.contrastText,
  zIndex: theme.zIndex.drawer + 1,
  transition: props.open
    ? theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    })
    : theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  marginLeft: props.open ? '211px' : '68px',
  width: '-webkit-fill-available',
  '@media (max-width:640px)': {
    marginLeft: theme.spacing(0),
  },
}));

export const StyledCustomToolbar = styled(CustomToolbar)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  '& .MuiList-root': {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

export const StyledCustomLink = styled(CustomLink)(() => ({
  flex: '1',
  padding: 0,
}));

export const StyledProfile = styled(Profile)(({ theme }) => ({
  '& .MuiListItemText-primary': {
    color: theme.background.black,
    ...theme.typography.body1,
  },
  '& .MuiIcon-root': {
    color: theme.visualization.grey,
  },
}));
