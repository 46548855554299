import { styled } from '@mui/system';

import Icon from '@mui/material/Icon';

const StyledMaterialIcon = styled(Icon)((props) => ({
  color: props.color ?? props.theme.boxShadow.black,
  fontSize: props.fontSize ?? props.theme.typography.subtitle1.fontSize,
}));

export default StyledMaterialIcon;
