import React from 'react';

import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

/** [CustomTypography] is used to present content as clearly and efficiently as possible
 * @constructor
 * @param {string} align - Defines alignment of Typography (left,right,center,justify)
 * @param {boolean} noWrap -If true, the text will not wrap,
 * but instead will truncate with a text overflow ellipsis.
 * @param {object} component - Defines Component type of Typography
 * @param {string} variant - Defines Variant of typography (ENUM TypographyVariantType)
 * @param {object} variantMapping -maps the variant prop to a range of different HTML element types
 * @param {bool} paragraph - If true, the element will be a paragraph element.
 * @param {bool} gutterBottom - If true, the text will have a bottom margin
 * @param {object} classes - to Override or extend the styles applied to the component
 * @param {object} sx - allows defining system overrides & additional CSS styles
 */

const CustomTypography = (props) => {
  const {
    align,
    children,
    noWrap,
    component,
    gutterBottom,
    paragraph,
    variant,
    variantMapping,
    classes,
    sx,
    ...rest
  } = props;
  return (
    <Typography
      align={align}
      variant={variant}
      noWrap={noWrap}
      component={component}
      variantMapping={variantMapping}
      paragraph={paragraph}
      gutterBottom={gutterBottom}
      classes={classes}
      sx={sx}
      {...rest}
    >
      {children}
    </Typography>
  );
};

CustomTypography.propTypes = {
  align: PropTypes.oneOf(['center', 'inherit', 'justify', 'left', 'right']),
  paragraph: PropTypes.bool,
  children: PropTypes.node,
  noWrap: PropTypes.bool,
  gutterBottom: PropTypes.bool,
  component: PropTypes.elementType,
  sx: PropTypes.shape({}),
  classes: PropTypes.shape({}),
  variant: PropTypes.string,
  variantMapping: PropTypes.shape({}),
};

CustomTypography.defaultProps = {
  align: 'inherit',
  gutterBottom: false,
  noWrap: false,
  paragraph: false,
  variant: 'body1',
};

export default CustomTypography;
