import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import AlertSeverityType from 'ReactKitComponents/Enum/AlertSeverityType';
import { useAuth } from '../../Contexts/AuthContext';
import { Wrapper, StyledLogin } from './Styles';

/**
 * @returns signup form
 */
const SignUp = () => {
  const { signup } = useAuth();
  const history = useHistory();
  const [error, setError] = useState(null);

  const userProps = {
    id: 'email',
    name: 'email',
    label: 'Email',
  };

  const passwordProps = {
    id: 'password',
    name: 'password',
    label: 'Password',
  };

  const confirmPasswordProps = {
    id: 'confirmPassword',
    name: 'confirmPassword',
    label: 'Confirm Password',
  };

  const redirectProps = {
    description: 'Already have an account? ',
    label: 'Login',
    to: '/',
  };

  const alertProps = {
    severity: AlertSeverityType.error,
    message: error,
  };

  const SignUpSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),

    confirmPassword: Yup.string()
      .required('Required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  });

  /** on submit, will fire firebase signup & navigates back */
  const SubmitHandler = async (e) => {
    try {
      setError(null);
      await signup(e.email, e.password);
      history.goBack();
    } catch (err) {
      setError(err);
    }
  };

  return (
    <Wrapper>
      <StyledLogin
        signUp
        title='Sign Up'
        userProps={userProps}
        passwordProps={passwordProps}
        confirmPasswordProps={confirmPasswordProps}
        redirectProps={redirectProps}
        submitHandler={(values) => SubmitHandler(values)}
        submitLabel='Sign Up'
        validationSchema={SignUpSchema}
        alertProps={error && alertProps}
      />
    </Wrapper>
  );
};

export default SignUp;
