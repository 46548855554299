import React from 'react';

import PropTypes from 'prop-types';

import { StyledPaper } from './styles';

/** [PaperBlock]  can be used to build surface or other elements for your application
 * @param {bool} square - If true, rounded corners are disabled.
 * @param {string} component - component used for the root node
 * @param {number} elevation - Shadow depth
 * @param {string} variant - variant to use.
 * @param {string|number} rounded - border radius of paper component
 * @param {Object} classes - to Override or extend the styles applied to component
 * @param {Object} sx - allows defining system overrides as well as additional CSS styles.
 */

const PaperBlock = React.forwardRef((props, ref) => {
  const {
    elevation,
    variant,
    square,
    component,
    rounded,
    classes,
    sx,
    children,
    ...rest
  } = props;

  return (
    <StyledPaper
      ref={ref}
      elevation={elevation}
      component={component}
      square={square}
      variant={variant}
      classes={classes}
      sx={sx}
      rounded={rounded}
      {...rest}
    >
      {children}
    </StyledPaper>
  );
});

PaperBlock.propTypes = {
  elevation: PropTypes.number,
  square: PropTypes.bool,
  variant: PropTypes.oneOfType([
    PropTypes.oneOf(['elevation', 'outlined']),
    PropTypes.string,
  ]),
  component: PropTypes.elementType,
  children: PropTypes.node.isRequired,
  rounded: PropTypes.number,
  classes: PropTypes.shape({}),
  sx: PropTypes.shape({}),
};

PaperBlock.defaultProps = {
  elevation: 1,
  variant: 'elevation',
  square: false,
  rounded: 12,
};

export default PaperBlock;
