import React from 'react';

import PropTypes from 'prop-types';

import { TableCellBody } from 'ReactKitComponents/Molecules/Tables/BasicTable/Style';

/** [TableCellData]
 * The component for rendering a individual table cell.
 * @param {Object} row - The data for the row.
 * @returns {ReactComponent} - The TableData component
 */
const TableCellData = (props) => {
  const { row } = props;

  const cells = Object.keys(row);
  return cells.map((cell) => (cell === 'dataIndex' || cell === 'config'
    ? null
    : (
      <TableCellBody
        id={`${row.dataIndex}-${cell}`}
        key={`${row.dataIndex}-${cell}`}
      >
        {row[cell]}
      </TableCellBody>
    )));
};

TableCellData.propTypes = {
  dataSource: PropTypes.shape({}),
};

TableCellData.defaultProps = {
  dataSource: {},
};

export default TableCellData;
