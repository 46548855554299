import { styled } from '@mui/system';

import CustomTypography from 'ReactKitComponents/Atoms/Typography/CustomTypography';
import OutlinedButton from 'ReactKitComponents/Atoms/Buttons/StandardButtons/OutlinedButton';
import CustomTextField from 'ReactKitComponents/Atoms/InputComponents/TextField/TextField';

export const GlimpseWrapper = styled('div')(() => ({
  '& .glimpse': {
    fontSize: '64px',
    lineHeight: '70px',
    fontWeight: 500,
    marginTop: '108px',
  },
  '& .dollar': {
    fontSize: '24px',
    lineHeight: '28px',
    fontWeight: 500,
  },
  '& .withJust': {
    fontSize: '48px',
    lineHeight: '58px',
    fontWeight: 300,
  },
  '& .youProvide': {
    fontSize: '48px',
    lineHeight: '58px',
    fontWeight: 300,
  },
  '& .number': {
    fontSize: '64px',
    lineHeight: '72px',
    fontWeight: 400,
  },
  '& .text': {
    fontSize: '24px',
    lineHeight: '30px',
    fontWeight: 500,
  },
  '& .donateButton': {
    fontSize: '24px',
    lineHeight: '34.56px',
    fontWeight: 400,
    paddingBottom: '9px',
    marginTop: '32px',
  },

  '& .sign': {
    display: 'inline-block',
    fontSize: '110px',
    lineHeight: '96px',
    fontWeight: 400,
    marginTop: '50px',
  },
}));

export const GlimpseTextDiv = styled('div')(() => ({
  width: '704px',
}));

export const SliderContainer = styled('div')(() => ({}));

export const SliderWrapper = styled('div')(() => ({
  position: 'absolute',
  marginTop: '69px',
  svg: {
    marginLeft: '-100px',

    circle: {
      cursor: 'pointer',
      fill: '#FEB80A',
      stroke: '#feb80a80',
      strokeWidth: '5px',
    },
    'path:nth-child(1)': {
      strokeWidth: '20px',
      stroke: '#BAD7FF',
    },
    'path:nth-child(2)': {
      strokeWidth: '20px',
      stroke: '#1E62C1',
    },
  },
}));

export const GlimpseText = styled(CustomTypography)(() => ({}));

export const AmountPayableDiv = styled('div')(() => ({
  marginTop: '34px',
  display: 'flex',
}));

export const GbpButton = styled(OutlinedButton)(({ theme }) => ({
  width: '192px',
  paddingTop: '16px',
  marginLeft: '32px',
  paddingBottom: '9px',
  marginTop: '80px',
  '& .MuiIcon-root': {
    fontSize: '30px',
    marginTop: '-7px',
  },
  color: theme.background.black,
  border: `${1}px solid ${theme.background.black}`,
  borderRadius: '50px',
  '&.MuiButtonBase-root:hover': {
    border: `${1}px solid ${theme.background.black}`,
  },
}));

export const AmountTypeDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  width: '416px',
  marginRight: '33px',
  '@media (min-width:900px) and (max-width:1200px)': {
    width: '250px',
  },
}));

export const WithJustText = styled(CustomTypography)(() => ({}));

export const DivWrapper = styled('div')(() => ({
  marginLeft: '112px',
}));

export const AmountTextField = styled(CustomTextField)(({ theme }) => ({
  '& .MuiFilledInput-input': {
    fontSize: '96px',
    lineHeight: '96px',
    marginBottom: '-20px',
    width: '416px',
  },
  '& .MuiFilledInput-root': {
    background: theme.visualization.transparent,
  },

  '& input[type=number]::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
  '& .MuiFilledInput-root.Mui-focused': {
    color: 'black',
  },
  '& .MuiFilledInput-root.Mui-disabled': {
    backgroundColor: theme.visualization.transparent,
  },
  '& .MuiInputBase-input.MuiFilledInput-input:focus': {
    backgroundColor: theme.background.white,
  },
  '& .MuiInputBase-input.MuiFilledInput-input:hover': {
    backgroundColor: theme.background.white,
  },
  '@media (min-width:900px) and (max-width:1400px)': {
    '& .MuiFilledInput-root': {
      width: '400px',
    },
  },
}));

export const ChildImage = styled('img')(() => ({
  '@media (min-width:900px) and (max-width:1400px)': {
    width: '270px',
    height: '260px',
    marginLeft: '-64px',
  },
  width: '400px',
  height: '390px',
  marginLeft: 0,
}));

export const AmountUsesDiv = styled('div')(() => ({
  marginTop: '24px',
  display: 'flex',
  justifyContent: 'flex-end',
}));

export const ContentWrapper = styled('div')(() => ({
  marginRight: '26px',
  '@media (min-width:900px) and (max-width:1400px)': {
    marginRight: '-15px',
  },
}));

export const YouProvideText = styled(CustomTypography)(() => ({
  marginTop: '24px',
  marginBottom: '40px',
}));

export const UsesDetailWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
}));

export const UsesDetailDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginRight: '32px',
  '@media (min-width:900px) and (max-width:1200px)': {
    marginRight: '12px',
  },
}));

export const CivilianCountText = styled(CustomTypography)(() => ({}));

export const CauseTypeName = styled(CustomTypography)(() => ({
  marginTop: '16px',
  width: '192px',
  '@media (min-width:900px) and (max-width:1200px)': {
    width: '120px',
  },
}));

export const ButtonDiv = styled('div')(() => ({}));

export const UsesBlockButton = styled(OutlinedButton)(({ theme }) => ({
  width: '271px',
  paddingTop: '16px',
  paddingBottom: '16px',
  marginRight: '250px',
  color: theme.background.black,
  backgroundColor: '#FEB80A',
  border: 'none',
  borderRadius: '50px',
  '&.MuiButtonBase-root:hover': {
    border: 'none',
    backgroundColor: '#FF9900',
  },
}));
