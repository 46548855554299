import React from 'react';

import PropTypes from 'prop-types';

import CustomList from 'ReactKitComponents/Atoms/ListComponents/List/CustomList';
import CustomListItem from 'ReactKitComponents/Atoms/ListComponents/ListItem/CustomListItem';
import CustomListItemAvatar from 'ReactKitComponents/Atoms/ListComponents/ListItemAvatar/CustomListItemAvatar';
import CustomListItemText from 'ReactKitComponents/Atoms/ListComponents/ListItemText/CustomListItemText';
import CustomMaterialIcon from 'ReactKitComponents/Atoms/Icons/CustomMaterialIcon/CustomMaterialIcon';
import CustomListItemButton from 'ReactKitComponents/Atoms/ListComponents/ListItemButton.js/ListItemButton';
import CustomListIcon from 'ReactKitComponents/Atoms/ListComponents/ListItemIcon/CustomListItemIcon';
import MaterialIconTypes from 'ReactKitComponents/Enum/MaterialIconTypes';
import CustomLink from 'ReactKitComponents/Atoms/Link/Link';
import { StyledCustomList, StyledCustomCollapse } from './Style';

/**
 * @param  {} data - data to be displayed inside the menu.
 * @param  {} primary - The main content element.
 * @param  {} secondary - The secondary content element.
 * @param  {} avatarChildren - content inside the avatar.
 */

const Profile = (props) => {
  const {
    data, primary, secondary, avatarChildren, ...rest
  } = props;

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <StyledCustomList {...rest}>
      <CustomListItemButton onClick={handleClick}>
        <CustomListItem>
          <CustomListItemAvatar>{avatarChildren}</CustomListItemAvatar>
          <CustomListItemText primary={primary} secondary={secondary} />
        </CustomListItem>
        {open ? (
          <CustomMaterialIcon
            icon='arrow_drop_up'
            variant={MaterialIconTypes.outlined}
          />
        ) : (
          <CustomMaterialIcon
            icon='arrow_drop_down'
            variant={MaterialIconTypes.outlined}
          />
        )}
      </CustomListItemButton>
      <StyledCustomCollapse in={open}>
        <CustomList>
          {data.map((item) => (
            <CustomLink to={data.link} target=' _blank '>
              <CustomListItemButton>
                <CustomListIcon
                  icon={item.menuIcon}
                  variant={item.menuIconVariant}
                />
                <CustomListItemText
                  primary={item.primary}
                  secondary={item.secondary}
                />
              </CustomListItemButton>
            </CustomLink>
          ))}
        </CustomList>
      </StyledCustomCollapse>
    </StyledCustomList>
  );
};

Profile.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      menuIcon: PropTypes.string,
      menuIconVariant: PropTypes.string,
      primary: PropTypes.string,
      secondary: PropTypes.string,
      link: PropTypes.string,
    }),
  ),
  avatarChildren: PropTypes.node,
  primary: PropTypes.string,
  secondary: PropTypes.string,
};

Profile.defaultProps = {
  data: [],
};

export default Profile;
