import { styled } from '@mui/system';

import BasicTable from 'ReactKitComponents/Molecules/Tables/BasicTable/BasicTable';

export const StyledTable = styled(BasicTable)(() => ({
  '& .MuiTableContainer-root': {
    background: 'transparent',
    color: '#000',
  },
  '& .MuiTableCell-root': {
    color: '#000',
  },
  '& .MuiTableCell-root.MuiTableCell-head': {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 600,
  },
}));

export const Wrapper = styled('div')(() => ({
  '& .highlight': {
    fontSize: '24px !important',
    lineHeight: '36px',
    fontWeight: 500,
  },
}));
