import React from 'react';

import PropTypes from 'prop-types';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { TableCellHead } from 'ReactKitComponents/Molecules/Tables/BasicTable/Style';

/** [TableColumn]
 * The table column component
 * @param {array} columns - Array of columns to be used as header in the table
 * @param {object} classes - to Override or extend the styles to table head
 * @param {Object} sx - allows defining system overrides as well as
 * additional CSS styles table head
 * @returns {ReactComponent} - The table component
 */

const TableColumn = (props) => {
  const { columns, classes, sx } = props;

  return (
    <TableHead classes={classes} sx={sx}>
      <TableRow>
        {columns.map((column) => (
          <TableCellHead
            key={column.dataIndex}
            align={column.align}
          >
            {column.name}
          </TableCellHead>
        ))}
      </TableRow>
    </TableHead>
  );
};

TableColumn.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  classes: PropTypes.shape({}),
  sx: PropTypes.shape({}),
};

TableColumn.defaultProps = {
  columns: [],
};

export default TableColumn;
