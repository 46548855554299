import React from 'react';

import PropTypes from 'prop-types';

import ListItemIcon from '@mui/material/ListItemIcon';

import CustomMaterialIcon from 'ReactKitComponents/Atoms/Icons/CustomMaterialIcon/CustomMaterialIcon';
/**
 * @param  {} icon - material iconName for the component
 * @param  {} variant - iconVariant, need to pass MaterialIconTypes enum.
 */
const CustomListIcon = (props) => {
  const {
    icon, variant, sx, classes, ...rest
  } = props;

  return (
    <ListItemIcon>
      <CustomMaterialIcon icon={icon} sx={sx} classes={classes} variant={variant} {...rest} />
    </ListItemIcon>
  );
};

CustomListIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  sx: PropTypes.shape({}),
  classes: PropTypes.shape({}),
};

export default CustomListIcon;
