import React from 'react';

import { Redirect } from 'react-router-dom';

import { useAuth } from 'Contexts/AuthContext';
import Donations from './Components/Donations/Donations';

const Transactions = () => {
  const { currentUser } = useAuth();

  return currentUser ? <Donations /> : <Redirect to='/login' />;
};

export default Transactions;
