import React from 'react';

import PropTypes from 'prop-types';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';

import TableCellData from './TableCellData';

/** [TableData]
 * Table Data Component
 * Displays the body of the table
 * @param {array} dataSource - Array of data to be used in body the table
 * @param {object} classes - to Override or extend the styles to table body
 * @param {Object} sx - allows defining system overrides as well as
 * additional CSS styles table body
 * @returns {ReactComponent} - The TableData component
 */

const TableData = (props) => {
  const { dataSource, classes, sx } = props;

  return (
    <TableBody classes={classes} sx={sx}>
      {dataSource.map((row) => (
        <TableRow
          key={`row-${row.dataIndex}`}
          selected={row.selected}
          hover={row.hover}
        >
          <TableCellData
            row={row}
          />
        </TableRow>
      ))}
    </TableBody>
  );
};

TableData.propTypes = {
  dataSource: PropTypes.arrayOf(PropTypes.object),
  classes: PropTypes.shape({}),
  sx: PropTypes.shape({}),
};

TableData.defaultProps = {
  dataSource: [],
};

export default TableData;
