import React from 'react';

import PropTypes from 'prop-types';

import StyledButton from './Style';

/** [CustomStyleButton] is a common component that can be used as different styles buttons
 * @constructor
 * @param {string} borderRadius - define border-radius of button
 * @param {string} backGroundColor - define background-color of button
 * @param {string} color - define text color of button
 * @param {node} children - Content of the component
 * @param {number} width - defines content width of button
 * @param {bool} disabled - defines button disable or not
 */

const CustomStyleButton = (props) => {
  const {
    children, disabled, onClick, ...args
  } = props;

  return (
    <StyledButton
      {...args}
      variant='contained'
      disabled={disabled}
      onClick={onClick}
      color='inherit'
    >
      {children}
    </StyledButton>
  );
};

CustomStyleButton.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

CustomStyleButton.defaultProps = {
  disabled: false,
  children: 'Primary',
  onClick: () => {},
};

export default CustomStyleButton;
