import React from 'react';
import { useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';

import CustomTypography from 'ReactKitComponents/Atoms/Typography/CustomTypography';
import CustomGrid from 'ReactKitComponents/Atoms/Grid/CustomGrid';
import {
  amount, donated, stat, transactionId,
} from 'Resources/Strings';
import {
  TransactionBlock, TrackBlock, StyledButton, Divider,
} from '../style';

const TransactionDetails = (props) => {
  const { donarId, charityData, donationDetails } = props;
  const history = useHistory();
  return (
    <>
      <TransactionBlock>
        <CustomGrid container>
          <CustomGrid item xs={6} md={6} lg={6}>
            <CustomTypography className='donate'>{donated}</CustomTypography>
            <CustomTypography className='name'>
              {charityData.name}
            </CustomTypography>
            <CustomTypography>
              {charityData.address && charityData.address.country}
            </CustomTypography>
          </CustomGrid>
          <CustomGrid item xs={6} md={6} lg={6}>
            <CustomTypography className='donate'>{amount}</CustomTypography>
            <CustomTypography className='amount' component='span'>
              £
              {Number(donationDetails.amount).toFixed(2)}
            </CustomTypography>
            <CustomTypography className='one' component='span'>
              one-time
            </CustomTypography>
          </CustomGrid>
        </CustomGrid>
        <Divider />
        <CustomGrid container>
          <CustomGrid item xs={6} md={6} lg={6}>
            <CustomTypography className='donate'>
              {transactionId}
            </CustomTypography>
            <CustomTypography className='id'>{donarId}</CustomTypography>
          </CustomGrid>
          <CustomGrid item xs={6} md={6} lg={6}>
            <CustomTypography className='donate'>{stat}</CustomTypography>
            <CustomTypography className='id'>
              {donationDetails.status}
            </CustomTypography>
          </CustomGrid>
        </CustomGrid>
      </TransactionBlock>
      <TrackBlock>
        <CustomTypography className='donate'>
          TRACK YOUR TRANSACTIONS
        </CustomTypography>
        <CustomTypography className='desc' component='div'>
          Click the button below to get complete details of the current and past
          transactions
        </CustomTypography>
        <StyledButton
          className='button'
          onClick={() => history.push('/my-donations')}
        >
          MY DONATIONS
        </StyledButton>
      </TrackBlock>
    </>
  );
};

export default TransactionDetails;

TransactionDetails.propTypes = {
  donarId: PropTypes.string,
  charityData: PropTypes.shape({
    name: PropTypes.string,
    address: PropTypes.shape({
      country: PropTypes.string,
    }),
  }),
  donationDetails: PropTypes.shape({
    amount: PropTypes.string,
    status: PropTypes.string,
  }),
};
