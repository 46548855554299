import React from 'react';

import PropTypes from 'prop-types';

import CustomComponent from './CustomComponent';
import StyledToolTip from './Style';

/** [CustomTooltip] is a common component that can be used for creating CustomizedTooltips
 * @constructor
 * @param {string} title - title of tooltip.
 * @param {bool} showArrow - If true, adds an arrow to the tooltip.
 * @param {node} children - Tooltip reference element.
 * @param {string} placement - Tooltip placement.
 * @param {bool} disableHoverListener - Do not respond to hover events.
 * @param {bool} disableFocusListener - Do not respond to focus events.
 * @param {bool} disableTouchListener - Do not respond to long press touch events.
 * @param {bool} open - If true, the tooltip is shown.
 * @param {func} onClose - Callback fired when the component requests to be closed.
 * @param {func} onOpen - Callback fired when the component requests to be open.
 * @param {number} enterDelay - The number of milliseconds to wait before showing the tooltip.
 * @param {number} leaveDelay - The number of milliseconds to wait before hiding the tooltip.
 * @param {bool} interactive - Makes a tooltip interactive.
 * @param {string} TransitionComponent - The component used for the transition.
 */

const CustomTooltip = (props) => {
  const {
    title,
    showArrow,
    children,
    placement,
    disableHoverListener,
    disableFocusListener,
    disableTouchListener,
    open,
    onClose,
    onOpen,
    enterDelay,
    leaveDelay,
    interactive,
    TransitionComponent,
    sx,
    classes,
    ...rest
  } = props;
  return (
    <StyledToolTip
      title={title}
      arrow={showArrow}
      placement={placement}
      disableHoverListener={disableHoverListener}
      disableFocusListener={disableFocusListener}
      disableTouchListener={disableTouchListener}
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      enterDelay={enterDelay}
      leaveDelay={leaveDelay}
      interactive={interactive}
      TransitionComponent={TransitionComponent}
      sx={sx}
      classes={classes}
      {...rest}
    >
      <CustomComponent>{children}</CustomComponent>
    </StyledToolTip>
  );
};

CustomTooltip.propTypes = {
  title: PropTypes.node,
  showArrow: PropTypes.bool,
  placement: PropTypes.string,
  children: PropTypes.node,
  disableHoverListener: PropTypes.bool,
  disableFocusListener: PropTypes.bool,
  disableTouchListener: PropTypes.bool,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  enterDelay: PropTypes.number,
  leaveDelay: PropTypes.number,
  interactive: PropTypes.bool,
  TransitionComponent: PropTypes.objectOf(PropTypes.shape),
  sx: PropTypes.shape({}),
  classes: PropTypes.shape({}),
};

export default CustomTooltip;
