import React from 'react';

import PropTypes from 'prop-types';

import ListItemButton from '@mui/material/ListItemButton';

/**
 * @param  {node} children - children components
 * @param  {Object} sx - The system prop that allows defining system
 * overrides as well as additional CSS styles.
 * @param  {Object} classes - Override or extend the styles applied to the component.
 * @param  {string} alignItems - Defines the align-items style property.
 * @param  {bool} autoFocus - If true, the list item is focused during the first mount.
 * @param  {bool} dense - If true, compact vertical padding designed
 * for keyboard and mouse input is used.
 * @param  {bool} disabled - If true, the component is disabled.
 * @param  {bool} disableGutters - If true, the left and right padding is removed.
 * @param  {bool} divider - If true, a 1px light border is added to the bottom of the list item.
 * @param  {string} focusVisibleClassName - This prop can help identify which element
 * has keyboard focus.
 * @param  {bool} selected - Use to apply selected styling.
 */
const CustomListItemButton = (props) => {
  const {
    children,
    sx,
    classes,
    alignItems,
    autoFocus,
    dense,
    disabled,
    disableGutters,
    divider,
    focusVisibleClassName,
    selected,
    ...rest
  } = props;
  return (
    <ListItemButton
      autoFocus={autoFocus}
      alignItems={alignItems}
      sx={sx}
      classes={classes}
      dense={dense}
      disabled={disabled}
      disableGutters={disableGutters}
      divider={divider}
      focusVisibleClassName={focusVisibleClassName}
      selected={selected}
      {...rest}
    >
      {children}
    </ListItemButton>
  );
};

CustomListItemButton.propTypes = {
  alignItems: PropTypes.string,
  children: PropTypes.node,
  sx: PropTypes.shape({}),
  classes: PropTypes.shape({}),
  dense: PropTypes.bool,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  disableGutters: PropTypes.bool,
  divider: PropTypes.bool,
  selected: PropTypes.bool,
  focusVisibleClassName: PropTypes.string,
};

export default CustomListItemButton;
