import React from 'react';

import { styled } from '@mui/system';
import { Tooltip, tooltipClasses } from '@mui/material';

const StyledToolTip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: theme.background.black,
    ...theme.typography.caption,
    fontWeight: theme.typography.body1.fontWeight,
    marginTop: '20px',
    borderRadius: '4px',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.background.black,
  },
}));

export default StyledToolTip;
