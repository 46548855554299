import React from 'react';

import PropTypes from 'prop-types';

import StyledTablePagination from './Style';

/** [TablePaginations] is a common MUi component that can be used for creating TablePaginations
  * @constructor
  * @param {number} count - Defines the total number of pages.
  * @param {number} page - The zero-based index of the current page.
  * @param {number} rowsPerPage - The number of rows per page.
  * @param {number} rowsPerPageOptions - Customizes the options of the rows per page select field.
  * @param {number} rowstoshow - Defines the number of data to show.
  * @param {number} onPageChange - Callback fired when the page is changed.
  * @param {number} onRowsPerPageChange - Callback fired when the number of
  * rows per page is changed.
  * @param {object} classes - Override or extend the styles applied to the component.
  * @param {object} backIconButtonProps - Props applied to the back arrow IconButton component.
  * @param {func} getItemAriaLabel - Accepts a function which returns a string value that
  * provides a user-friendly name for the current page.
  * @param {func} labelDisplayedRows - Customize the displayed rows label.
  * @param {node} labelRowsPerPage - Customize the rows per page label.
  * @param {elementType} ActionsComponent - The component used for displaying the actions.
  * @param {object} nextIconButtonProps - Props applied to the next arrow.
  * @param {object} SelectProps - Props applied to the rows per page Select element.
  * @param {bool} showFirstButton - If true, show the first-page button.
  * @param {bool} showLastButton - If true, show the last-page button.
  * @param {object} sx - The system prop that allows defining system overrides
  * as well as additional CSS styles.
  */

const TablePaginations = (props) => {
  const {
    count,
    page,
    component,
    rowsPerPage,
    rowstoshow,
    classes,
    rowsPerPageOptions,
    onPageChange,
    onRowsPerPageChange,
    backIconButtonProps,
    getItemAriaLabel,
    labelDisplayedRows,
    labelRowsPerPage,
    ActionsComponent,
    nextIconButtonProps,
    SelectProps,
    showFirstButton,
    showLastButton,
    sx,
    ...rest
  } = props;
  return (
    <StyledTablePagination
      component={component}
      count={count}
      classes={classes}
      rowsPerPageOptions={rowsPerPageOptions}
      rowstoshow={rowstoshow}
      page={page}
      onPageChange={onPageChange}
      ActionsComponent={ActionsComponent}
      backIconButtonProps={backIconButtonProps}
      getItemAriaLabel={getItemAriaLabel}
      labelDisplayedRows={labelDisplayedRows}
      labelRowsPerPage={labelRowsPerPage}
      nextIconButtonProps={nextIconButtonProps}
      SelectProps={SelectProps}
      showFirstButton={showFirstButton}
      showLastButton={showLastButton}
      sx={sx}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={onRowsPerPageChange}
      {...rest}
    />
  );
};

TablePaginations.propTypes = {
  count: PropTypes.number,
  page: PropTypes.number,
  component: PropTypes.elementType,
  rowstoshow: PropTypes.number,
  rowsPerPage: PropTypes.number,
  onPageChange: PropTypes.func,
  ActionsComponent: PropTypes.elementType,
  backIconButtonProps: PropTypes.shape({}),
  classes: PropTypes.shape({}),
  getItemAriaLabel: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  labelDisplayedRows: PropTypes.func,
  labelRowsPerPage: PropTypes.node,
  SelectProps: PropTypes.shape({}),
  showFirstButton: PropTypes.bool,
  showLastButton: PropTypes.bool,
  sx: PropTypes.shape({}),
  nextIconButtonProps: PropTypes.shape({}),
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
};
TablePaginations.defaultProps = {
  rowsPerPage: 2,
  component: 'div',
};
export default TablePaginations;
