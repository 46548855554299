import React from 'react';

import CustomGrid from 'ReactKitComponents/Atoms/Grid/CustomGrid';
import headerLogo from 'assets/Images/BannerImages/headerLogo.png';
import {
  StyledFooter,
  LogoGrid,
  HashtagGrid,
  StandWithtext,
  HashtagText,
  HashtagDiv,
  ShareCauseButton,
  FooterWrapper,
} from './Styles';

const Footer = () => (
  <StyledFooter>
    <FooterWrapper>
      <CustomGrid container spacing={3}>
        <LogoGrid item xs={12} sm={12} md={5}>
          <img src={headerLogo} alt='logo' />
        </LogoGrid>
        <HashtagGrid item xs={12} sm={12} md={6}>
          <StandWithtext className='standWith'>
            Stand with Ukraine
          </StandWithtext>
          <HashtagDiv>
            <HashtagText className='supportText'>#stopwar</HashtagText>
            <HashtagText className='supportText'>#supportukraine</HashtagText>
          </HashtagDiv>
          <ShareCauseButton className='button'>
            SHARE THE CAUSE
          </ShareCauseButton>
        </HashtagGrid>
      </CustomGrid>
    </FooterWrapper>
  </StyledFooter>
);

export default Footer;
