import { styled } from '@mui/system';

import PaperBlock from 'ReactKitComponents/Atoms/PaperBlock/PaperBlock';
import OutlinedButton from 'ReactKitComponents/Atoms/Buttons/StandardButtons/OutlinedButton';
import RaisedButton from 'ReactKitComponents/Atoms/Buttons/StandardButtons/RaisedButton';

export const StyleCard = styled(PaperBlock)(({ theme }) => ({
  padding: theme.spacing(3),
  position: 'relative',
  border: '1px solid #B3B3B3',
  borderRadius: '8px',
  '& .support': {
    fontSize: '14px',
    lineHeight: '19.12px',
    marginTop: '39px',
  },
  '& .cause': {
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 500,
    display: 'inline',
  },

  '& .fav': {
    color: '#D5D5D5',
    fontSize: '20px',
    cursor: 'pointer',
    position: 'absolute',
    top: '20px',
    right: '20px',
  },
  '& .funds': {
    fontSize: '14px',
    lineHeight: '19.12px',
    marginBottom: '8px',
  },
  '& .amount': {
    fontSize: '18px',
    lineHeight: '22px',
    fontWeight: 600,
  },
  '& span.MuiLinearProgress-root': {
    background: '#E0E0E0',
    height: 6,
    marginTop: 6,
    '& .MuiLinearProgress-barColorPrimary': {
      background: '#FEB80A',
    },
  },
}));

export const Divider = styled('div')(() => ({
  borderBottom: '1px solid #E0E0E0',
  marginTop: '22px',
  marginBottom: '16px',
}));

export const TextWrapper = styled('div')(() => ({
  height: '47px',
}));

export const StyledOutlined = styled(OutlinedButton)(({ theme }) => ({
  color: theme.background.black,
  backgroundColor: 'transparent',
  borderRadius: '50px',
  border: '1px solid #000000',
  fontSize: '16px !important',
  lineHeight: '23.04px !important',
  marginTop: '20px',
  marginLeft: 0,
  paddingBottom: '0px',
  ':hover': {
    background: theme.background.black,
    color: theme.background.white,
    boxShadow: 'none',
    border: '1px solid #000000',
  },
  '@media (max-width:1308px)': {
    fontSize: '14px !important',
  },
  maxWidth: '133px',
  minHeight: '43px',
}));

export const StyledRaised = styled(RaisedButton)(({ theme }) => ({
  color: theme.background.black,
  background: '#FEB80A',
  ':hover': {
    background: ' #FF9900',
    border: 'none',
    boxShadow: 'none',
  },
  '@media (max-width:1308px)': {
    fontSize: '14px !important',
  },
  borderRadius: '50px',
  fontSize: '16px !important',
  lineHeight: '23.04px !important',
  border: 'none',
  boxShadow: 'none',
  margin: 0,
  marginTop: '12px',
  maxWidth: '133px',
  minHeight: '43px',
  paddingBottom: '0px',
}));
