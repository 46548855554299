import React from 'react';

import PropTypes from 'prop-types';

import AvatarVariantTypes from 'ReactKitComponents/Enum/AvatarVariantTypes';
import { StyledAvatar, StyledBadge } from './style';

/** [Custom Avatar] is a common component that is used for graphical representation of user
 * @constructor
 * @param {string} src              - Defines Image File Path
 * @param {string} alt              - Defines Alternate Text for Image
 * @param {string} avatarvarient    - Defines shape of Avatar (cirular, square,rounded)
 * @param {string} backgroundColor  - Defines Background color for Avatar
 * @param {boolean} invisible       - Defines visibility of badge
 * @param {object} anchorOrigin     - Defines the Position of Badge (Top/Bottom && Left/Right)
 * @param {string} color            - Defines the color of Badge
 * @param {string} bgcolor          - Defines the background color of Badge
 * @param {string} overlap          - Defines Overlap of Badge (rectangular/circular) ENUM
 * @param {string} badgevarient     - Defines type of Badge (standard/dot)
 * @param {string} sizes            - Defines the size of Avatar (large/small) Choosed from Enum
 * @param {object} imgProps         - Defines attributes applied to the img element if the component
 *                                    is used to display an image.
 * @param {string} srcSet           - Defines the srcSet attribute for the img element.
 *                                    Use this attribute for responsive image display.
 * @param {elementType} component   - Defines the component used for the root node.
 *                                    Either a string to use a HTML element or a component.
 * @param {object} sx               - Defines the system prop that allows defining system
 *                                    overrides as well as additional CSS styles.
 *
 * To Use Badge Pass Badgevariant Type and BadgeOverlapType and color as Props
 * For Image Avatar pass src and alt as a Props
 */

const CustomAvatar = (props) => {
  const {
    src,
    alt,
    avatarvariant,
    overlap,
    anchorOrigin,
    badgevariant,
    invisible,
    imgProps,
    sizes,
    srcSet,
    bgcolor,
    component,
    children,
    sx,
    ...rest
  } = props;

  return (
    <StyledBadge
      overlap={overlap}
      anchorOrigin={anchorOrigin}
      variant={badgevariant}
      sizes={sizes}
      invisible={invisible}
      {...rest}
    >
      <StyledAvatar
        src={src}
        alt={alt}
        bgcolor={bgcolor}
        variant={avatarvariant}
        imgProps={imgProps}
        sizes={sizes}
        srcSet={srcSet}
        component={component}
      >
        {children}
      </StyledAvatar>
    </StyledBadge>
  );
};

CustomAvatar.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  bgcolor: PropTypes.string,
  overlap: PropTypes.string,
  anchorOrigin: PropTypes.shape({
    horizontal: PropTypes.oneOf(['left', 'right']),
    vertical: PropTypes.oneOf(['top', 'bottom']),
  }),
  avatarvariant: PropTypes.string,
  badgevariant: PropTypes.string,
  invisible: PropTypes.bool,
  imgProps: PropTypes.shape({}),
  sizes: PropTypes.string,
  srcSet: PropTypes.string,
  component: PropTypes.elementType,
  sx: PropTypes.shape({}),
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Object),
  ]),
};

CustomAvatar.defaultProps = {
  avatarvariant: AvatarVariantTypes.circular,
};

export default CustomAvatar;
