import React, { useCallback } from 'react';

import PropTypes from 'prop-types';

/**
 * Common Image component for the app
 * @param {String} Image
 * @param {String} height
 * @param {String} width
 * @param {String} alt
 * @returns JSX
 */

const ImageLoader = ({
  image, height, width, alt, ...rest
}) => {
  const images = require.context('assets/Images', true);
  const loadImage = useCallback(
    (imageName) => images(`./${imageName}`).default,
    [],
  );
  return (
    <div height={height} width={width} {...rest}>
      <img src={loadImage(image)} alt={alt} />
    </div>
  );
};

ImageLoader.propTypes = {
  image: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  alt: PropTypes.string,
};

ImageLoader.defaultProps = {
  image: 'moonraft-logo.svg',
  height: '100%',
  width: '100%',
  alt: 'image',
};

export default ImageLoader;
