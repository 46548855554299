import { createTheme, alpha } from '@mui/material/styles';

import colorPalette from './colorPalette';
import typography from './Typography';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1280,
    },
  },
  htmlFontSize: 16,
  /**
   * To use Typography
   * @example
   * pagination: {
   * fontSize: theme.typography.caption.fontSize
   * }
   *
   * We can also spread the entire style in this manner
   * @example
   * pagination: {
      ...theme.typography.caption,
    },
   */
  typography: { ...typography },
  fonts: {
    // dmSans: 'DM Sans',
    // dmSansBold: 'DM Sans-Bold',
    // dmSansMedium: 'DM Sans-Medium',
  },
  palette: {
    primary: colorPalette.colors.primary,
    secondary: colorPalette.colors.secondary,
    info: colorPalette.info,
    warning: colorPalette.warning,
    success: colorPalette.success,
    error: colorPalette.error,
  },
  colors: { ...colorPalette.colors },
  fontColors: { ...colorPalette.fontColors },
  background: { ...colorPalette.background },
  visualization: { ...colorPalette.visualization },
  shades: {},
  gradients: {
    primary: `linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), ${colorPalette.colors.primary.main}`,
  },
  boxShadow: {
    primary: '-5px 5px 45px rgba(0, 0, 0, 0.06)',
    button:
      '0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 5px 5px -3px rgba(0, 0, 0, 0.2)',
    chartTooltip: '0px 5px 15px rgba(68, 68, 79, 0.1)',
  },
  rounded: {
    small: '8px',
    medium: '12px',
    large: '20px',
  },
  shape: {
    borderRadius: 8,
  },
  spaces: {},
  components: {
    MuiInput: {
      styleOverrides: {
        root: {
          border: '1px solid rgba(0,0,0,0.32)',
          borderRadius: 8,
          alignItems: 'center',
          transition: 'border 0.3s ease',
          color: colorPalette.visualization.grey,
          '&.Mui-focused': {
            border: `1px solid ${colorPalette.colors.primary.main}`,
            color: colorPalette.colors.primary.main,
          },
          '&.Mui-error': {
            color: colorPalette.error.main,
            borderColor: colorPalette.error.main,
          },
        },
        input: {
          padding: '9px 12px',
        },
        multiline: {
          paddingTop: '1.5rem',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: '5px',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontWeight: 500,
          fontSize: '0.875rem',
          lineHeight: '22px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: colorPalette.visualization.grey,
          borderRadius: 8,
        },
        root: {
          '&.Mui-focused': {
            color: colorPalette.colors.primary.main,
          },
          '&.Mui-error': {
            color: colorPalette.background.black,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'inherit',
          borderRadius: 8,
          outline: 'none',
        },
        containedPrimary: {
          '&:hover': {
            backgroundColor: colorPalette.visualization.sky,
          },
        },
        textSizeLarge: {
          minWidth: '145px',
          minHeight: '44px',
        },
        outlinedSizeLarge: {
          minWidth: '145px',
          minHeight: '44px',
        },
        containedSizeLarge: {
          minWidth: '145px',
          minHeight: '44px',
        },
        textSizeMedium: {
          minWidth: '104px',
          minHeight: '40px',
        },
        outlinedSizeMedium: {
          minWidth: '104px',
          minHeight: '40px',
        },
        containedSizeMedium: {
          minWidth: '104px',
          minHeight: '40px',
        },
        textSizeSmall: {
          minWidth: '78px',
          minHeight: '28px',
        },
        outlinedSizeSmall: {
          minWidth: '76px',
          minHeight: '28px',
        },
        containedSizeSmall: {
          minWidth: '76px',
          minHeight: '28px',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: colorPalette.colors.primary.main,
          '&.Mui-checked + .MuiSwitch-track': {
            backgroundColor: colorPalette.fontColors.disabledLight,
          },
          '&.Mui-disabled': {
            color: colorPalette.fontColors.disabled,
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            color: colorPalette.fontColors.disabledLight,
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontWeight: 500,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          minWidth: '120px',
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          width: 200,
          margin: '20px',
        },
        rail: {
          height: '8px',
          borderRadius: '5px',
          color: alpha(colorPalette.fontColors.disabled, 0.5),
        },
        track: {
          height: '8px',
          borderRadius: '5px',
          color: colorPalette.visualization.aqua,
          '$vertical &': {
            width: 8,
          },
        },
        thumb: {
          width: '18px',
          height: '18px',
          color: colorPalette.colors.primary.contrastText,
          boxShadow: ' 0px 0px 4px rgba(0, 0, 0, 0.25)',
          '& .Mui-disabled': {
            width: '18px',
            height: '18px',
            color: colorPalette.fontColors.disabled,
          },
          '&.Mui-focusVisible': {
            boxShadow: 'none',
          },
          ':hover': {
            width: '26px',
            height: '26px',
            boxShadow: `0px 0px 0px 11px ${alpha(
              colorPalette.visualization.aqua,
              0.2,
            )}`,
            border: `2px solid ${colorPalette.visualization.aqua}`,
          },
        },
        mark: {
          width: '8px',
          height: '8px',
          backgroundColor: colorPalette.colors.primary.light,
          borderRadius: '8px',
          mixBlendMode: 'multiply',
        },
        markActive: {
          backgroundColor: colorPalette.visualization.aquaDark,
        },
        valueLabel: {
          color: colorPalette.visualization.aqua,
          backgroundColor: colorPalette.visualization.transparent,
          '&.MuiSlider-dragging': {
            top: 28,
          },
        },
        vertical: {
          width: 8,
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          padding: 20,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          MuiSlider: {
            root: {
              width: 200,
              margin: '20px',
            },
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        padding: {
          width: '100%',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: colorPalette.colors.primary.main,
          minWidth: 'auto',
          paddingRight: '20px',
          paddingLeft: '8px',
          height: '24px',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
          minWidth: 0,
          margin: 0,
        },
        gutters: {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          paddingRight: '24px',
          borderRadius: 8,
          ':focus': {
            backgroundColor: colorPalette.colors.primary.contrastText,
            borderRadius: 8,
            borderWidth: 1,
          },
        },
        outlined: {
          '&.Mui-error': {
            color: colorPalette.background.black,
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
    // MuiTableContainer: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: colorPalette.background.white,
    //     },
    //   },
    // },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid #000',
          padding: '14.5px 16px',
          boxSizing: 'border-box',
        },
        head: {
          borderTop: '1px solid #000',
        },
        body: {},
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorDefault: {
          backgroundColor: colorPalette.background.white,
        },
        colorPrimary: {
          color: colorPalette.background.background,
          backgroundColor: colorPalette.background.white,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        colorDefault: {
          backgroundColor: colorPalette.fontColors.disabled,
          color: colorPalette.fontColors.secondary,
        },
      },
    },
    MuiDropzoneSnackbar: {
      styleOverrides: {
        errorAlert: {
          display: 'flex',
          alignItems: 'flex-start',
          backgroundColor: colorPalette.colors.primary.contrastText,
          color: colorPalette.error.main,
        },
        closeButton: {
          marginTop: '8px',
          padding: '0px',
          height: 'auto',
          width: 'auto',
        },
        successAlert: {
          backgroundColor: colorPalette.colors.primary.contrastText,
          color: colorPalette.success.main,
        },
        infoAlert: {
          backgroundColor: colorPalette.colors.primary.contrastText,
          color: colorPalette.info.main,
        },
        warningAlert: {
          backgroundColor: colorPalette.colors.primary.contrastText,
          color: colorPalette.warning.main,
        },
        message: {
          fontSize: '0.875rem',
          fontWeight: '500',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: '-5px 5px 45px rgba(0, 0, 0, 0.06)',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          minHeight: '32px',
          minWidth: '72px',
          fontSize: '0.875rem',
          fontWeight: 500,
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          '& .Mui-expanded': {
            margin: '0px 0px',
          },
        },
        content: {
          '& .Mui-expanded': {
            margin: '12px 0px',
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          ':before': {
            height: 0,
          },
          ':first-child': {
            borderTopLeftRadius: 9,
            borderTopRightRadius: 9,
          },
          ':last-child': {
            borderBottomLeftRadius: 9,
            borderBottomRightRadius: 9,
          },
          '& .Mui-expanded': {
            borderRadius: 9,
            '+ div': {
              borderTopLeftRadius: 9,
              borderTopRightRadius: 9,
            },
          },
        },
        rounded: {
          borderRadius: 9,
          marginBottom: 10,
          ':first-child': {
            borderTopLeftRadius: 9,
            borderTopRightRadius: 9,
          },
          ':last-child': {
            borderBottomLeftRadius: 9,
            borderBottomRightRadius: 9,
          },
          '& .Mui-expanded': {
            borderRadius: 9,
            boxShadow: '0',
            '& + div': {
              borderTopLeftRadius: 9,
              borderTopRightRadius: 9,
            },
          },
        },
      },
    },
  },
});

export default theme;
