import React from 'react';
import { useLocation } from 'react-router-dom';

import NewsInfoContainer from 'Containers/LandingPageContainer/NewsInfoContainer';
import BaseLayout from 'Components/Layout/BaseLayout';
import RefugeeDetailContainer from 'Containers/LandingPageContainer/RefugeeDetailContainer';
import CauseTypeContainer from 'Containers/LandingPageContainer/CauseTypeContainer';
import { saveState } from 'LocalStorage';
import { RedirectPassword } from 'Resources/Strings';
import { BannerImg, Wrapper } from './style';

/**
 * * [LandingPage] is the parent component for all other
 * * components used in the landing page.
 * @returns <>
 *            <NewsInfo />
 *            <AmountDonate />
 *            <EarnedRewards />
 *            <CauseTypeContainer />
 *          </>
 */

const LandingPage = () => {
  const { search } = useLocation();
  const redirect = new URLSearchParams(search).get('redirect');

  if (redirect) {
    saveState('redirected', RedirectPassword);
  }
  return (
    <>
      <BannerImg>
        <Wrapper>
          <NewsInfoContainer />
        </Wrapper>
      </BannerImg>
      <BaseLayout>
        <Wrapper>
          <RefugeeDetailContainer />
          <CauseTypeContainer />
        </Wrapper>
      </BaseLayout>
    </>
  );
};

export default LandingPage;
