import { styled } from '@mui/system';

import CustomTypography from 'ReactKitComponents/Atoms/Typography/CustomTypography';
import CustomGrid from 'ReactKitComponents/Atoms/Grid/CustomGrid';
import OutlinedButton from 'ReactKitComponents/Atoms/Buttons/StandardButtons/OutlinedButton';
import CustomStyleButton from 'ReactKitComponents/Atoms/Buttons/CustomisedButtons/CustomStyleButtons/CustomStyleButton';

export const StyledFooter = styled('div')(({ theme }) => ({
  backgroundColor: theme.fontColors.black,
  paddingLeft: theme.spacing(8.1),
  paddingTop: theme.spacing(9.2),
  paddingRight: theme.spacing(8.1),
  paddingBottom: theme.spacing(13.2),
  '@media (max-width:700px)': {
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '120px',
  },
  '& .button': {
    fontSize: '24px',
    fontWeight: 400,
    lineHeight: '34.56px',
    paddingBottom: '0px',
  },
  '& .standWith': {
    fontSize: '96px',
    fontWeight: 400,
    lineHeight: '90px',
  },
  '& .supportText': {
    fontSize: '24px',
    fontWeight: 400,
    lineHeight: '28px',
  },
}));

export const StyledManchester = styled(CustomTypography)(({ theme }) => ({
  color: theme.background.white,
  marginLeft: '16px',

  maxWidth: 200,
}));

export const StandWithtext = styled(CustomTypography)(({ theme }) => ({
  color: theme.background.white,
  marginLeft: '16px',
  marginBottom: '8px',
}));

export const HashtagDiv = styled('div')(() => ({
  display: 'flex',
  marginBottom: '40px',
}));

export const FooterWrapper = styled('div')(() => ({
  maxWidth: '1300px',
  margin: '0 auto',
}));

export const HashtagText = styled(CustomTypography)(({ theme }) => ({
  color: theme.background.white,
  marginLeft: '16px',
  fontSize: '24px',
  lineHeight: '28px',
}));

export const LogoAndNameDiv = styled('div')(() => ({
  display: 'flex',
  width: '400px',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const LogoGrid = styled(CustomGrid)(() => ({
  img: {
    width: '331px',
    height: '44px',
  },
}));

export const HashtagGrid = styled(CustomGrid)(() => ({}));

export const ManchesterLogo = styled('img')(() => ({
  position: 'relative',
  zIndex: 1,
}));

export const DanieleLogo = styled('img')(() => ({
  borderBottomLeftRadius: '50%',
  borderBottomRightRadius: '50%',
  height: '40px',
  border: '4px solid yellow',
  borderTop: '1px solid black',
}));

export const ShareCauseButton = styled(OutlinedButton)(({ theme }) => ({
  color: theme.background.white,
  width: '270px',
  border: '1px solid white',
  borderRadius: '50px',
  padding: '10px',
  height: '63px',
  ...theme.typography.h6,
  ':hover': {
    border: `1px solid ${theme.background.white}`,
  },
}));

export const DonateButton = styled(CustomStyleButton)(({ theme }) => ({
  padding: '10px',
  border: 'none',
  height: '63px',
  ...theme.typography.h6,
  borderRadius: '50px',
  ':hover': {
    border: 'none',
    backgroundColor: '#FF9900',
  },
}));
