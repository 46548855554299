import React from 'react';

import PropTypes from 'prop-types';

import ListItemText from '@mui/material/ListItemText';

/**
 * @param  {} children - primary text or element
 * @param  {} secondary - secondary text or element
 * @param  {} disableTypography - to disable Typography when we pass typography as primary
 *                                explicitly
 * @param  {} inset - if true children will be indented
 */
const CustomListItemText = (props) => {
  const {
    children, secondary, disableTypography, inset, sx, classes, ...rest
  } = props;
  return (
    <ListItemText
      secondary={secondary}
      disableTypography={disableTypography}
      inset={inset}
      sx={sx}
      classes={classes}
      {...rest}
    >
      {children}
    </ListItemText>
  );
};

CustomListItemText.propTypes = {
  children: PropTypes.node,
  secondary: PropTypes.node,
  disableTypography: PropTypes.bool,
  inset: PropTypes.bool,
  sx: PropTypes.shape({}),
  classes: PropTypes.shape({}),
};

export default CustomListItemText;
