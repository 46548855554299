/// Strings being used in every modules can be defined here and can be used as
///  `<div>{DefinedString}<div>`

/* Common Strings */
export const learnReact = 'Learn React';
export const removeLater = 'remove later';
/* Common Strings */
export const HEADLINE = 'Moonraft Innovation Labs. A UST global company';
export const COPYRIGHT = '© 2021 Copyright';
export const moonraftLogo = 'moonraft logo';
export const notificationError = 'Some Error came up..';
export const notificationSuccess = 'Your edit was successfully submitted';
export const userMenuDate = '8 Jan, 2021';
export const userName = 'Moonraft';
export const userProfile = 'Marketing Administrator';
export const myProfile = 'My Profile';
export const logOut = 'Log Out';
export const datePickerMessage = 'Date must be less than today';
export const datePickerFormat = 'DD/MM/YYYY';
export const recentTransaction = 'Recent Transaction';
export const giftId = 'GiftID:';
export const enteredDate = 'Entered Date';
export const recievedDate = 'Recieved Date';
export const numberofShares = 'Number of Shares';
export const tabAmount = 'Amount';
export const status = 'Status';
export const viewActivity = 'View Activity';
export const type = 'Type';
export const contribute = 'Contribute';
export const grant = 'Grant';
export const exchange = 'Exchange';
export const manageYourAccount = 'Manage your account';
export const investmentPerformance = 'Investment Performance';
export const nav = 'NAV';
export const marketValue = 'Market Value';
export const units = 'Units';
export const marketMovement = 'Market Movement';

/* Header Strings */
export const manchester = 'Moonraft Stands With Ukraine';
export const impact = 'IMPACT';
export const organisations = 'ORGANISATIONS';
export const info = 'INFO';
export const logIn = 'Log in/Sign up';
export const donateNow = 'DONATE NOW';
export const donate = 'DONATE';
export const myAccount = 'My Donation';
export const logOutText = 'Log out';

export const signupText = 'Please enter your email for Donating';
export const RedirectPassword = 'Moonraft@123';
export const loginText = 'Please enter your email and password to login';

/* NewsInfo Strings */
export const timesCivilianNumber = '2665';
export const timesCivilianText = 'civilian casualties since Feb 24, 2022';
export const googleCivilianNumber = 'More than 3.9 million';
export const googleCivilianText = 'civilian casualties since Feb 24, 2022';
export const bloombergCivilianNumber = 'More than 8 billion';
export const bloombergCivilianText = 'US assistance sent to Ukraine over the past year';
export const standWithUkraine = 'Let us all stand with Ukraine';
export const helpDescription = ' Overnight, the lives of Ukrainians worldwide were completely disrupted, their democracy attacked, and millions of people were suddenly displaced. As fighting intensifies, humanitarian agencies stand ready to deliver life-saving assistance.';

/* AmountDonate Strings */
export const glimpse = 'Get a glimpse of the impact you can make';
export const dollar = 'GBP';
export const withJust = 'With just';
export const youCanProvide = 'you can almost provide';

/* CauseType Strings */
export const ukraineNeedHelp = 'Ukraine currently needs all your help.';
export const ukrainehelpDescription = 'Millions of people are in urgent need both inside Ukraine and in surrounding countries. The United Nations warns that continued conflict in Ukraine could displace 10 million people, making it one of the largest refugee crises in history. Tens of millions of people are at risk as the country spirals further into a humanitarian crisis.';

/* Charities Strings */
export const supported = 'Supported Causes';
export const funds = 'Funds Raised';
export const charityFinder = 'Charity Finder';

/* Thankyou Strings */
export const donated = 'Donated To';
export const amount = 'Donation Amount';
export const transactionId = 'Transaction ID';
export const stat = 'Status';
