import React from 'react';

import PropTypes from 'prop-types';

import StyledLink from './Style';

/** [CustomLink]
 * @constructor
 * @param {string} to - provides href
 * @param {node} children - children of CustomLink component
 * @param {string} color - define text color
 * @param {objectOf} className - defines className for different style
 */

const CustomLink = (props) => {
  const {
    to, children, color, className, target, hoverColor, ...rest
  } = props;

  return (
    <StyledLink
      to={to}
      underline='none'
      color={color}
      hoverColor={hoverColor}
      className={className}
      target={target}
      {...rest}
    >
      {children}
    </StyledLink>
  );
};

CustomLink.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  children: PropTypes.node,
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  className: PropTypes.string,
  target: PropTypes.string,
};

CustomLink.defaultProps = {
  to: '',
  target: '_self',
};

export default CustomLink;
