import { styled } from '@mui/system';

import CircularProgress from '@mui/material/CircularProgress';
import CustomTypography from 'ReactKitComponents/Atoms/Typography/CustomTypography';

export const StyledCircularFiller = styled(CircularProgress)((props) => ({
  color: props.theme.visualization.transparent,
}));

export const StyledCircularProgress = styled(CircularProgress)(() => ({
  position: 'absolute',
  left: 0,
}));

export const StyledTypography = styled(CustomTypography)((props) => ({
  fontSize: props.theme.typography.body1.fontSize,
  fontWeight: props.theme.typography.body1.fontWeight,
  lineHeight: props.theme.typography.body2.lineHeight,
}));
