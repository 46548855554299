import React from 'react';

import PropTypes from 'prop-types';
import Collapse from '@mui/material/Collapse';

/** [CustomCollapse] component serves as a wrapper component for most of the CSS utility needs.
 * @constructor
 * @param {Object} sx - allows defining system overrides as well as additional CSS styles.
 * @param {string} children - content of the component.
 * @param {string,func,object} component - The component used for the root node.
 * Either a string to use a DOM element or a component.
 * @param {Object} classes - Override or extend the styles applied to the component.
 * @param {string} collapsedSize - The width (horizontal) or height (vertical)
 * of the container when collapsed.
 * @param {string} easing - The transition timing function.
 * @param {string} orientation - The transition orientation.
 */
const CustomCollapse = (props) => {
  const {
    addEndListener,
    component,
    children,
    sx,
    classes,
    collapsedSize,
    easing,
    orientation,
    ...rest
  } = props;

  return (
    <Collapse
      addEndListener={addEndListener}
      orientation={orientation}
      component={component}
      sx={sx}
      classes={classes}
      collapsedSize={collapsedSize}
      easing={easing}
      {...rest}
    >
      {children}
    </Collapse>
  );
};

CustomCollapse.propTypes = {
  addEndListener: PropTypes.func,
  easing: PropTypes.string,
  orientation: PropTypes.string,
  classes: PropTypes.shape({}),
  collapsedSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  component: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.shape({}),
  ]),
  children: PropTypes.node,
  sx: PropTypes.shape({}),
};

export default CustomCollapse;
