import React from 'react';

import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';

/** [CustomAppbar] displays information and actions relating to the current screen .
 * @constructor
 * @param {string} color - color of component.It supports only theme colors.
 * @param {bool} position -  positioning type.
 * @param {bool} enableColorOnDark - If true, the color prop is applied in dark mode
 * @param {Object} classes - to Override or extend the styles applied to component
 * @param {string} children - content of the component.
 * @param {Object} sx - allows defining system overrides as well as additional CSS styles.
 */
const CustomAppbar = (props) => {
  const {
    color, position, enableColorOnDark, children, classes, sx, ...rest
  } = props;
  return (
    <AppBar
      color={color}
      position={position}
      enableColorOnDark={enableColorOnDark}
      classes={classes}
      sx={sx}
      {...rest}
    >
      {children}
    </AppBar>
  );
};

export default CustomAppbar;

CustomAppbar.propTypes = {
  color: PropTypes.string,
  position: PropTypes.oneOf(['absolute', 'fixed', 'relative', 'static', 'sticky']),
  classes: PropTypes.shape({}),
  sx: PropTypes.shape({}),
  enableColorOnDark: PropTypes.bool,
  children: PropTypes.node,
};

CustomAppbar.defaultProps = {
  color: 'primary',
  position: 'fixed',
  enableColorOnDark: false,
};
