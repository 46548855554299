import { styled } from '@mui/system';

import AuthenticationForm from 'ReactKitComponents/Molecules/AuthenticationForm/AuthenticationForm';

export const StyledLogin = styled(AuthenticationForm)(() => ({
  maxWidth: '600px',
  backgroundColor: '#F7F2E9',
  borderRadius: '16px',
  width: '599px',
  height: '583px',
  boxSizing: 'border-box',
  '&.MuiPaper-root': {
    alignItems: 'initial',
    padding: '56px 123px',
    form: { alignItems: 'initial' },
    '& .MuiTypography-root:nth-child(1)': {
      fontSize: '24px',
      lineHeight: '22px',
      fontWeight: 500,
      marginBottom: '8px',
    },
    '& .MuiTypography-root:nth-child(2)': {
      fontSize: '18px',
      lineHeight: '24px',
      fontWeight: 400,
      marginBottom: '32px',
    },
    '& .MuiTypography-root:last-child': {
      fontSize: '16px',
      lineHeight: '22px',
      fontWeight: 400,
      marginTop: '30px',
    },
    a: {
      fontSize: '16px',
      lineHeight: '20px',
      fontWeight: 400,
      color: '#000000',
      textDecoration: 'underline',
      display: 'inline-block',
    },
    '& .MuiTextField-root': {
      width: '100%',
      maxWidth: '100%',
      marginBottom: '16px',
      backgroundColor: '#ffffff',
      borderRadius: '8px',
      height: 'auto',
      fieldset: {
        border: '1px solid #BDBDBD',
      },
    },
    '& .MuiButton-root': {
      width: '111px',
      height: '40px',
      borderRadius: '50px',
      fontSize: '18px',
      lineHeight: '25.92px',
      fontWeight: 400,
      backgroundColor: '#FEB80A',
      color: '#000000',
      boxShadow: 'none',
      padding: '8px 32px',
      cursor: 'pointer',
      marginTop: '10px',
    },
  },
}));

export const Wrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  'p:not(:last-child)': {
    color: 'red ',
  },
}));
