const refugeeData = [
  {
    refugeeNumber: '3',
    refugeeText: 'Refugees food & water',
  },
  {
    refugeeNumber: '2',
    refugeeText: 'Families financial help',
  },
  {
    refugeeNumber: '8',
    refugeeText: 'Injured soldiers medical help',
  },
  {
    refugeeNumber: '1',
    refugeeText: 'Orphaned children shelter',
  },
];

export default refugeeData;
