import React from 'react';

import PropTypes from 'prop-types';
import MuiModal from '@mui/material/Modal';

/** [Modals] is a common component that provides
 * a solid foundation for creating dialogs, popovers, lightboxes, or whatever else.
 * @constructor
 * @param {bool} open - If true, the modal is open.
 * @param {func} onClose -Callback fired when the component requests to be closed.
 * @param {element} children -A single child content element.
 */
const Modal = React.forwardRef((props, ref) => {
  const {
    open, onClose, children, ...rest
  } = props;

  return (
    <MuiModal
      ref={ref}
      open={open}
      onClose={onClose}
      {...rest}
    >
      {children}
    </MuiModal>
  );
});

export default Modal;

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired,
};
