import React from 'react';

import PropTypes from 'prop-types';

import CustomMaterialIcon from 'ReactKitComponents/Atoms/Icons/CustomMaterialIcon/CustomMaterialIcon';
import MaterialIconTypes from 'ReactKitComponents/Enum/MaterialIconTypes';
import { StyledButton } from './Style';

/** [OutlinedButton] is a common component that can be used for a basic OutlinedButton component
 * @constructor
 * @param {string} color - The color of the component.
 * @param {node} children - The content of the component.
 * @param {boolean} disabled - whether button is disabled or not
 * @param {string} startIcon - Icon to be displayed at the start.
 * @param {string} endIcon - Icon to be displayed at the end.
 * @param {string} size - size of the component
 * @param {bool} fullWidth - width as per parent component or not
 * @param {string} startIconVariant - Variant of start icon
 * @param {string} endIconVariant - Variant of end icon
 */

const OutlinedButton = React.forwardRef((props, ref) => {
  const {
    color,
    disabled,
    children,
    onClick,
    startIcon,
    endIcon,
    size,
    fullWidth,
    startIconVariant,
    endIconVariant,
    ...rest
  } = props;

  return (
    <StyledButton
      ref={ref}
      variant='outlined'
      disabled={disabled}
      color={color}
      onClick={onClick}
      size={size}
      fullWidth={fullWidth}
      startIcon={
        startIcon && (
          <CustomMaterialIcon icon={startIcon} variant={startIconVariant} />
        )
      }
      endIcon={
        endIcon && (
          <CustomMaterialIcon icon={endIcon} variant={endIconVariant} />
        )
      }
      {...rest}
    >
      {children}
    </StyledButton>
  );
});

OutlinedButton.propTypes = {
  onClick: PropTypes.func,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  startIcon: PropTypes.string,
  endIcon: PropTypes.string,
  size: PropTypes.string,
  fullWidth: PropTypes.bool,
  startIconVariant: PropTypes.string,
  endIconVariant: PropTypes.string,
};

OutlinedButton.defaultProps = {
  color: 'primary',
  disabled: false,
  onClick: () => {},
  endIconVariant: MaterialIconTypes.outlined,
  startIconVariant: MaterialIconTypes.outlined,
  size: 'medium',
  fullWidth: false,
};

export default OutlinedButton;
