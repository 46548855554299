import { styled } from '@mui/system';

import PaperBlock from 'ReactKitComponents/Atoms/PaperBlock/PaperBlock';
import CustomTextField from 'ReactKitComponents/Atoms/InputComponents/TextField/TextField';
import RaisedButton from 'ReactKitComponents/Atoms/Buttons/StandardButtons/RaisedButton';
import CustomTypography from 'ReactKitComponents/Atoms/Typography/CustomTypography';
import CustomAlert from 'ReactKitComponents/Atoms/Alerts/CustomAlert';

export const StyledPaper = styled(PaperBlock)(({ theme, ...props }) => ({
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  backgroundColor: '#F7F2E9',
  width: '380px',
  height: '330px',

  '& .great': {
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: '22px',
    marginBottom: '8px',
  },
  '& .desc': {
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '24px',
  },
  '& .powered': {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '14px',
    marginBottom: '8px',
  },

  '& .error': {
    display: props.error ? 'inline-block' : 'none',
  },

  img: {
    width: '147px',
    height: '25px',
  },

  '& .wrapper': {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export const StyledForm = styled('form')(() => ({
  display: 'flex',
  flexDirection: 'column',
  '& .button': {
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '25.92px',
    color: '#000000',
  },
  '& .forget': {
    textAlign: 'right',
    display: 'inline-block',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    textDecoration: 'underline',
    color: '#000000',
  },
}));

export const StyledInput = styled(CustomTextField)(() => ({
  boxSizing: 'border-box',
  marginTop: '24px',
  width: '100%',
  backgroundColor: '#ffffff',
  borderRadius: '8px',
  height: 'auto',

  '@media (max-width:350px)': {
    minWidth: '250px',
  },
}));

export const StyledButton = styled(RaisedButton)(({ theme, ...props }) => ({
  margin: theme.spacing(0),
  width: '138px',
  border: 'none',
  paddingBottom: '0px',
  boxShadow: 'none',
  marginTop: props.error ? '0px' : '0px',
  backgroundColor: '#FEB80A',
  ':hover': {
    border: 'none',
    backgroundColor: '#FF9900',
    boxShadow: 'none',
  },
  borderRadius: '50px',
}));

export const Title = styled(CustomTypography)(({ theme, ...props }) => ({
  marginBottom: props.alert ? theme.spacing(2) : theme.spacing(4),
}));

export const HelperText = styled(CustomTypography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  maxWidth: '300px',
  width: '100%',
  paddingLeft: theme.spacing(1),
  fontSize: '12px',
  lineHeight: '16px',
  marginTop: '8px',
  color: theme.palette.error.main,
  '@media (max-width:350px)': {
    maxWidth: '250px',
  },
}));

export const Alert = styled(CustomAlert)(() => ({
  width: '100%',

  '@media (max-width:350px)': {
    maxWidth: '250px',
  },
  color: '#fff',
}));
