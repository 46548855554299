import React, { useContext, useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  sendPasswordResetEmail,
} from 'firebase/auth';

import { auth, db } from 'firebase';
import {
  setDoc, doc,
} from 'firebase/firestore';
import { removeState } from 'LocalStorage';

const AuthContext = React.createContext();

export const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  const createDB = async (user) => {
    try {
      await setDoc(doc(db, 'users', user.user.uid), {
        email: user.user.email,
        id: user.user.uid,
        creationTime: user.user.metadata.creationTime,
      });
      removeState('redirected');
    } catch (e) {
      console.error('Error adding document: ', e);
      throw e;
    }
  };

  const signup = (email, password) => createUserWithEmailAndPassword(auth, email, password)
    .then((user) => createDB(user))
    .catch((error) => {
      throw error.code;
    });

  const login = (email, password) => signInWithEmailAndPassword(auth, email, password)
    .then((user) => console.log(user))
    .catch((error) => {
      throw error.code;
    });

  const resetPassword = (email) => sendPasswordResetEmail(auth, email);

  function logout() {
    return signOut(auth);
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    login,
    signup,
    logout,
    resetPassword,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
