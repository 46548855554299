import { styled } from '@mui/system';

import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';

export const StyledBadge = styled(Badge)((props) => ({
  '& .MuiBadge-badge': {
    borderRadius: '50%',
    right: '12%',
    bottom: '22%',
    height: props.sizes === 'large' ? '16px' : '9px',
    width: props.sizes === 'large' ? '16px' : '9px',
    border:
      props.sizes === 'large'
        ? `2px solid ${props.theme.background.white}`
        : `1px solid ${props.theme.background.white}`,
    '& .MuiBadge-dot': {
      background: props.color,
      height: props.sizes === 'large' ? '16px' : '9px',
      width: props.sizes === 'large' ? '16px' : '9px',
    },
  },
}));

export const StyledAvatar = styled(Avatar)((props) => {
  switch (props.sizes) {
    case 'large':
      return {
        fontSize: props.theme.typography.h1.fontSize,
        width: '80px',
        height: '80px',
        '& :first-of-type': {
          fontSize: props.theme.typography.h1.fontSize,
        },
        background: props.bgcolor ?? props.theme.visualization.sky,
      };
    case 'small':
      return {
        fontSize: props.theme.typography.subtitle2.fontSize,
        width: '24px',
        height: '24px',
        '& :first-of-type': {
          fontSize: props.theme.typography.subtitle2.fontSize,
        },
        background: props.bgcolor ?? props.theme.visualization.sky,
      };
    case 'medium':
      return {
        fontSize: props.theme.typography.h5.fontSize,
        width: '40px',
        height: '40px',
        '& :first-of-type': {
          fontSize: props.theme.typography.h5.fontSize,
        },
        background: props.bgcolor ?? props.theme.visualization.sky,
      };
    default:
      return {
        background: props.bgcolor ?? props.theme.visualization.sky,
      };
  }
});
