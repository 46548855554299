import React, { useState } from 'react';

import * as Yup from 'yup';
import { useFormik } from 'formik';

import AlertVariantType from 'ReactKitComponents/Enum/AlertVariantType';
import AlertSeverityType from 'ReactKitComponents/Enum/AlertSeverityType';
import CustomCollapse from 'ReactKitComponents/Atoms/Collapse/Collapse';
import CustomCircularProgress from 'ReactKitComponents/Atoms/ProgressBar/CircularProgressBar/CircularProgress';
import { useAuth } from '../../Contexts/AuthContext';
import {
  StyledPaper,
  StyledForm,
  StyledInput,
  StyledButton,
  Title,
  StyledText,
  SignUp,
  HelperText,
  Alert,
  Wrapper,
} from './Styles';

/**
 * @returns Reset form with email field
 */
const ResetPassword = () => {
  const { resetPassword } = useAuth();
  const [showAlert, setShowAlert] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);

  /** on submit, will fire firebase signup & navigates back */
  const SubmitHandler = async (e) => {
    setLoading(true);
    setShowAlert(true);
    setError(null);
    try {
      await resetPassword(e.email);
      setLoading(false);
      setSuccess('Reset Link sent to mail');
    } catch (err) {
      setError('User not Found !');
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: { email: '' },
    validationSchema: Yup.object().shape({
      email: Yup.string().email('Invalid email').required('Required'),
    }),
    onSubmit: (values) => SubmitHandler(values),
  });

  const handleAlert = () => error && setShowAlert(false);

  return (
    <Wrapper>
      <StyledPaper>
        <Title className='title' component='div' alert={error}>
          Reset Password
        </Title>
        {(error || success) && (
          <CustomCollapse orientation='horizontal' in={showAlert}>
            <Alert
              variant={AlertVariantType.filled}
              severity={
                success ? AlertSeverityType.success : AlertSeverityType.error
              }
            >
              {success || error}
            </Alert>
          </CustomCollapse>
        )}
        <StyledForm onSubmit={formik.handleSubmit} onFocus={handleAlert}>
          <StyledInput
            id='email'
            name='email'
            type='email'
            onChange={formik.handleChange}
            value={formik.values.email}
            label='Email'
          />
          <HelperText>{formik.errors.email}</HelperText>
          <StyledButton
            type='submit'
            disabled={!(formik.isValid && formik.dirty) || loading}
          >
            {loading ? <CustomCircularProgress size={20} /> : 'submit'}
          </StyledButton>
          <StyledText>
            <SignUp to='/login'>Login</SignUp>
          </StyledText>
          <StyledText>
            Dont have an account?
            <SignUp to='/signup'>Sign Up</SignUp>
          </StyledText>
        </StyledForm>
      </StyledPaper>
    </Wrapper>
  );
};

export default ResetPassword;
