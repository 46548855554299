import React, { useState } from 'react';

import PropTypes from 'prop-types';

import Child from 'assets/Images/BannerImages/childImage.png';
import CustomTypography from 'ReactKitComponents/Atoms/Typography/CustomTypography';
import { glimpse, withJust, youCanProvide } from 'Resources/Strings';
import {
  GlimpseTextDiv,
  GlimpseWrapper,
  GlimpseText,
  AmountPayableDiv,
  WithJustText,
  AmountTextField,
  ChildImage,
  YouProvideText,
  UsesDetailDiv,
  UsesDetailWrapper,
  CivilianCountText,
  CauseTypeName,
  DivWrapper,
} from './style';

const AmountDonate = (props) => {
  const { data } = props;
  const [value, setValue] = useState('10');
  // commented for later use
  // const [anchorEl, setAnchorEl] = useState(null);
  // const [currency, setCurrency] = useState('GBP');
  // const menuItems = [
  //   {
  //     text: 'GBP',
  //   },
  //   {
  //     text: 'Dollar',
  //   },
  // ];
  // const onClick = (e) => {
  //   setAnchorEl(e.currentTarget);
  // };
  // const onClose = () => {
  //   setAnchorEl(null);
  // };
  // const onItemClick = (e) => {
  //   setCurrency(e.nativeEvent.target.outerText);
  //   setAnchorEl(null);
  // };
  return (
    <GlimpseWrapper>
      <GlimpseTextDiv>
        <GlimpseText className='glimpse'>{glimpse}</GlimpseText>
      </GlimpseTextDiv>
      <AmountPayableDiv>
        <ChildImage src={Child} alt='child' />
        <DivWrapper>
          <WithJustText className='withJust'>{withJust}</WithJustText>
          <div>
            <CustomTypography className='sign'>£</CustomTypography>
            <AmountTextField
              defaultValue='10'
              value={value}
              variant='filled'
              type='number'
              onChange={(e) => setValue(e.target.value)}
            />
            {/* <GbpButton
              className='dollar'
              endIcon='arrow_drop_down'
              startIconVariant={MaterialIconTypes.outlined}
              onClick={onClick}
            >
              {currency}
            </GbpButton>
            <Dropdown
              menuItems={menuItems}
              anchorEl={anchorEl}
              onClose={onClose}
              elevation={0}
              onItemClick={onItemClick}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'center',
              }}
            /> */}
          </div>

          <YouProvideText className='youProvide'>
            {youCanProvide}
          </YouProvideText>
          <UsesDetailWrapper>
            {data
              && data.map((item) => (
                <UsesDetailDiv>
                  <CivilianCountText className='number'>
                    {item.refugeeNumber * value}
                  </CivilianCountText>
                  <CauseTypeName className='text'>
                    {item.refugeeText}
                  </CauseTypeName>
                </UsesDetailDiv>
              ))}
          </UsesDetailWrapper>
        </DivWrapper>
      </AmountPayableDiv>
    </GlimpseWrapper>
  );
};

AmountDonate.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      refugeeNumber: PropTypes.string,
      refugeeText: PropTypes.string,
    }),
  ),
};

export default AmountDonate;
