import React from 'react';

import PropTypes from 'prop-types';

import InputAdornment from '@mui/material/InputAdornment';

import StyledTextField from './Style';

/** [CustomTextField] is a common component that can be used as
 *  a TextField with variants like outline or filled
 * @constructor
 * @param label - For the label of the input
 * @param defaultValue - For default value of input
 * @param required - If input required or not before submission
 * @param disabled - If input disabled or not
 * @param variant - variant of the input (outlined or filled)
 * @param error - if error on input or not
 * @param type - type of input (email, password, search, number)
 * @param multiline - for multiple lines in input field
 * @param maxRows - max no of lines on multiline field
 * @param rows - no of lines on multiline field
 * @param helperText - to display helper text below input
 * @param placeholder - to display placeholder
 * @param fullWidth - if input takes fullwidth or not
 * @param onChange - function triggered when input value changes
 * @param {any} inputAdornment - it can be a string or an Icon
 *                               component which is used as an
 *                               input adornment
 * @param {string} position - position of the adornment
 */
const CustomTextField = (props) => {
  const {
    label,
    defaultValue,
    required,
    disabled,
    variant,
    error,
    type,
    multiline,
    maxRows,
    rows,
    helperText,
    placeholder,
    fullWidth,
    onChange,
    adornmentPosition,
    inputAdornment,
    className,
    sx,
    classes,
    ...rest
  } = props;
  return (
    <StyledTextField
      sx={sx}
      classes={classes}
      defaultValue={defaultValue}
      label={label}
      required={required}
      disabled={disabled}
      variant={variant}
      error={error}
      type={type}
      className={className}
      multiline={multiline}
      maxRows={maxRows}
      rows={rows}
      helperText={helperText}
      placeholder={placeholder}
      fullWidth={fullWidth}
      onChange={onChange}
      InputProps={{
        startAdornment:
          adornmentPosition === 'start' ? (
            <InputAdornment position='start'>{inputAdornment}</InputAdornment>
          ) : null,
        endAdornment:
          adornmentPosition === 'end' ? (
            <InputAdornment position='end'>{inputAdornment}</InputAdornment>
          ) : null,
      }}
      {...rest}
    />
  );
};

CustomTextField.propTypes = {
  defaultValue: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  error: PropTypes.bool,
  type: PropTypes.string,
  multiline: PropTypes.bool,
  maxRows: PropTypes.number,
  rows: PropTypes.number,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  fullWidth: PropTypes.bool,
  onChange: PropTypes.func,
  adornmentPosition: PropTypes.string,
  inputAdornment: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  sx: PropTypes.shape({}),
  classes: PropTypes.shape({}),
};

export default CustomTextField;
