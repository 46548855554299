import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import TablePaginations from 'ReactKitComponents/Molecules/Paginations/TablePagination/TablePaginations';
import TableColumn from './TableColumn/TableColumn';
import TableData from './TableData/TableData';
import { StyledTableContainer, StyledTable } from './Style';
import TableHeader from './TableHeader/TableHeader';

/** [BasicTable]
 * The BasicTable component is a table component that can be
 * used to display data in a tabular format.
 * @param {string} tableTitle - Title of the table
 * @param {bool} showHeader - Enable/Disable header of the table
 * @param  {bool} denseTable - bool to make the table denser
 * @param {String} tableIconName - Name of the table icon to be used in the header
 * @param {String} tableIconDesc - Tooltip title for table Icon
 * @param {func} iconCallback - callback fired when the table icon is clicked
 * @param {array} columns - Array of columns to be used as header in the table
 * @param {array} rowstoshow - Defines the number of data to show.
 * @param {array} rowsPerPageOptions - Customizes the options of the rows per page select field
 * @param {array} showHeader - If true, show the header in the table
 * @param {array} showFirstButton - If true, show the first-page button.
 * @param {array} showLastButton - If true, show the last-page button.
 * @param {array} showPagination - If true, show the Pagination in the table
 * @param {array} dataSource - Array of data to be used in body the table
 * @param {object} headerClasses - to Override or extend the styles to table title
 * @param {Object} headerSx - allows defining system overrides as well as
 * additional CSS styles table title
 * @param {object} tableClasses - to Override or extend the styles to table
 * @param {Object} tableSsx - allows defining system overrides as well as
 * additional CSS styles table
 * @param {object} tableContainerClasses - to Override or extend the styles to table container
 * @param {Object} tableContainerSx - allows defining system overrides as well as
 * additional CSS styles table container
 * @param {object} tableColumnClasses - to Override or extend the styles to table column
 * @param {Object} tableColumnSx - allows defining system overrides as well as
 * additional CSS styles table column
 * @param {object} tableBodyClasses - to Override or extend the styles to table body
 * @param {Object} tableBodySx - allows defining system overrides as well as
 * additional CSS styles table body
 * @return {ReactElement} - Returns the table component
 */

const BasicTable = (props) => {
  const {
    dataSource,
    columns,
    size,
    rowstoshow = 2,
    rowsPerPageOptions,
    tableTitle,
    showHeader,
    showFirstButton,
    showLastButton,
    showPagination,
    tableIconName,
    iconCallback,
    headerClasses,
    headerSx,
    tableClasses,
    tableSx,
    tableContainerClasses,
    tableContainerSx,
    tableColumnClasses,
    tableColumnSx,
    tableBodyClasses,
    tableBodySx,
    tableIconDesc,
    ...rest
  } = props;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState();
  useEffect(() => {
    setRowsPerPage(rowstoshow);
  }, []);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const tableDataSlice = dataSource.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  /**
   * Checks whether column and dataSource is empty or not
   */
  if (!dataSource.length || !columns.length) return <></>;

  /**
   * Displays table Header and table body
   * Display Header only if showHeader is true
   */
  return (
    <div {...rest}>
      {showHeader && (
        <TableHeader
          tableTitle={tableTitle}
          tableIconName={tableIconName}
          tableIconDesc={tableIconDesc}
          iconCallback={iconCallback}
          classes={headerClasses}
          sx={headerSx}
        />
      )}
      <StyledTableContainer classes={tableContainerClasses} sx={tableContainerSx}>
        <StyledTable
          size={size}
          aria-label='basic table'
          classes={tableClasses}
          sx={tableSx}
        >
          <TableColumn columns={columns} classes={tableColumnClasses} sx={tableColumnSx} />
          <TableData
            dataSource={showPagination ? tableDataSlice : dataSource}
            classes={tableBodyClasses}
            sx={tableBodySx}
          />
        </StyledTable>
      </StyledTableContainer>
      {showPagination && (
        <TablePaginations
          count={dataSource.length}
          showFirstButton={showFirstButton}
          showLastButton={showLastButton}
          rowsPerPageOptions={rowsPerPageOptions}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </div>
  );
};

BasicTable.propTypes = {
  dataSource: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object),
  size: PropTypes.oneOf(['small', 'medium']),
  showHeader: PropTypes.bool,
  showPagination: PropTypes.bool,
  showFirstButton: PropTypes.bool,
  showLastButton: PropTypes.bool,
  tableTitle: PropTypes.string,
  rowstoshow: PropTypes.number,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  tableIconName: PropTypes.string,
  iconCallback: PropTypes.func,
  headerClasses: PropTypes.shape({}),
  headerSx: PropTypes.shape({}),
  tableClasses: PropTypes.shape({}),
  tableSx: PropTypes.shape({}),
  tableContainerClasses: PropTypes.shape({}),
  tableContainerSx: PropTypes.shape({}),
  tableColumnClasses: PropTypes.shape({}),
  tableColumnSx: PropTypes.shape({}),
  tableIconDesc: PropTypes.string,
  tableBodyClasses: PropTypes.shape({}),
  tableBodySx: PropTypes.shape({}),
};

BasicTable.defaultProps = {
  dataSource: [],
  columns: [],
  size: 'medium',
  showHeader: false,
  showPagination: false,
  showFirstButton: false,
  showLastButton: false,
  tableTitle: '',
  tableIconName: '',
  iconCallback: () => { },
};

export default BasicTable;
