import { styled } from '@mui/system';

import CustomHeader from 'ReactKitComponents/Molecules/Headers/Header';
import CustomTypography from 'ReactKitComponents/Atoms/Typography/CustomTypography';
import CustomLink from 'ReactKitComponents/Atoms/Link/Link';
import OutlinedButton from 'ReactKitComponents/Atoms/Buttons/StandardButtons/OutlinedButton';

export const StyledHeader = styled(CustomHeader)(({ theme, ...props }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  zIndex: 999,
  marginRight: '64px',
  marginLeft: '64px',
  position: 'static',
  '& .MuiAppBar-root': {
    position: 'absolute',
    overflow: 'hidden',
    margin: props.bg ? 0 : 'inherit',
    '@media (max-width:531px)': {
      marginRight: '0px',
      marginLeft: '0px',
    },
  },
  '& .MuiToolbar-root': {
    paddingRight: 0,
    paddingLeft: 0,
  },

  '& .MuiPaper-root': {
    marginRight: '64px',
    marginLeft: '64px',
    boxShadow: 'none',
    backgroundColor: theme.visualization.transparent,
    borderBottom: `${1}px solid ${
      props.bg ? theme.background.black : theme.background.white
    }`,
  },
  '& .button': {
    fontSize: '18px',
    lineHeight: '25.92px',
    fontWeight: 400,
    paddingBottom: '1px',
    marginRight: 0,
  },
}));

export const StyledManchester = styled(CustomTypography)(({ theme }) => ({
  marginTop: '5px',
  paddingLeft: theme.spacing(1.9),
  paddingRight: theme.spacing(1),
  color: theme.background.white,
  width: '125px',
  fontSize: theme.typography.subtitle2.fontSize,
  '@media (max-width:769px)': {
    paddingRight: theme.spacing(1),
    width: '80px',
  },
  cursor: 'pointer',
}));

export const ManchesterLogo = styled('img')(({ theme }) => ({
  paddingRight: theme.spacing(1.5),
  cursor: 'pointer',
  '@media (max-width:769px)': {
    paddingRight: theme.spacing(1),
    marginTop: '3px',
    width: '20px',
    height: '40px',
  },
}));

export const DanieleLogo = styled('img')(({ theme }) => ({
  borderBottomLeftRadius: '50%',
  borderBottomRightRadius: '40%',
  cursor: 'pointer',
  paddingLeft: theme.spacing(1.5),
  '@media (max-width:769px)': {
    paddingLeft: theme.spacing(1),
    width: '20px',
    height: '40px',
    marginTop: '3px',
    borderBottomLeftRadius: '50%',
    borderBottomRightRadius: '30%',
  },
}));

export const VerticalLine = styled('div')(({ theme }) => ({
  borderLeft: `${2}px solid ${theme.background.white}`,
  height: '40px',
}));

export const ImpactText = styled(CustomLink)(({ theme, ...props }) => ({
  paddingRight: theme.spacing(5),
  color: props.bg ? theme.background.black : theme.background.white,
  '@media (max-width:1000px)': {
    paddingRight: theme.spacing(2),
    display: 'none',
  },
  '@media (min-width:1000px) and (max-width:1104px)': {
    paddingRight: theme.spacing(2),
  },
}));

export const OrganisationText = styled(CustomLink)(({ theme, ...props }) => ({
  paddingRight: theme.spacing(5),
  color: props.bg ? theme.background.black : theme.background.white,
  '@media (max-width:1000px)': {
    paddingRight: theme.spacing(2),
    display: 'none',
  },
  '@media (min-width:1000px)and (max-width:1104px)': {
    paddingRight: theme.spacing(2),
  },
}));

export const InfoText = styled(CustomLink)(({ theme, ...props }) => ({
  paddingRight: theme.spacing(5),
  color: props.bg ? theme.background.black : theme.background.white,
  '@media (max-width:1000px)': {
    paddingRight: theme.spacing(2),
    display: 'none',
  },
  '@media (min-width:1000px) and (max-width:1104px)': {
    paddingRight: theme.spacing(2),
  },
}));

export const LogInButton = styled(OutlinedButton)(({ theme, ...props }) => ({
  color: props.bg ? theme.background.black : theme.background.white,
  border: `${1}px solid ${
    props.bg ? theme.background.black : theme.background.white
  }`,
  borderRadius: '20px',
  '&.MuiButtonBase-root:hover': {
    backgroundColor: theme.background.white,
    color: theme.background.black,
    border: `1px solid ${theme.background.white}`,
  },
}));

export const DonateButton = styled(OutlinedButton)(({ theme }) => ({
  color: theme.background.black,
  backgroundColor: theme.visualization.yellow,
  borderRadius: '20px',
  border: 'none',
  ':hover': {
    border: 'none',
    backgroundColor: '#FF9900',
  },
}));

export const LogoDiv = styled('div')(() => ({
  display: 'flex',
  marginRight: 'auto',
  alignItems: 'center',
  img: { cursor: 'pointer', width: '331px', height: '44px' },
}));

export const NavigationDiv = styled('div')(() => ({
  display: 'flex',
  margin: 'auto',
  '& .headerLink': {
    fontSize: '18px',
    lineHeight: '25.92px',
    fontWeight: 400,
    marginTop: '8px',
  },
}));

export const LogInDiv = styled('div')(() => ({
  display: 'flex',
  marginLeft: 'auto',
}));
