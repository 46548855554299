import React from 'react';

import PropTypes from 'prop-types';

import StyledLinearProgress from './Style';

/** [LinearStatic] is a common component that can be used for a
 * linear progressbars with different type of variant
 * @constructor
 * @param {number} value - progress value
 * @param {string} color - color of progress bar
 * @param {string} variant - which type of progress bar want to use
 * @param {object} sx - which type of progress bar want to use
 * @param {number} valueBuffer - The value for the buffer variant. Value between 0 and 100.
 */

const LinearStatic = (props) => {
  const {
    color, value = 20, variant, sx, valueBuffer, ...rest
  } = props;
  return (
    <StyledLinearProgress
      variant={variant}
      value={value}
      color={color}
      valueBuffer={valueBuffer}
      sx={sx}
      {...rest}
    />
  );
};

LinearStatic.propTypes = {
  value: PropTypes.number,
  color: PropTypes.string,
  variant: PropTypes.string,
  sx: PropTypes.shape({}),
  valueBuffer: PropTypes.number,
};

export default LinearStatic;
