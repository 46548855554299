import React from 'react';
import { useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';

import MaterialIconTypes from 'ReactKitComponents/Enum/MaterialIconTypes';
import CustomGrid from 'ReactKitComponents/Atoms/Grid/CustomGrid';
import { ukraineNeedHelp, ukrainehelpDescription } from 'Resources/Strings';
import CustomLink from 'ReactKitComponents/Atoms/Link/Link';
import {
  UkraineHelpText,
  HelpDescriptionText,
  CauseTypeWrapper,
  UkraineHelpTextGrid,
  CauseHelpDescriptionGrid,
  CauseTypeText,
  CauseDescription,
  CauseSupporter,
  CauseContainer,
  StyledIconButton,
  Container,
  MapContainer,
  ImageContainer,
  ImageWrapper,
  StyledOutline,
} from './style';

const CauseTypes = (props) => {
  const { data } = props;
  const history = useHistory();
  return (
    <CauseTypeWrapper>
      <CustomGrid container columnSpacing={17}>
        <UkraineHelpTextGrid item xs={12} sm={6} md={6}>
          <UkraineHelpText className='needHelp'>
            {ukraineNeedHelp}
          </UkraineHelpText>
        </UkraineHelpTextGrid>
        <CauseHelpDescriptionGrid item xs={12} sm={6} md={6}>
          <HelpDescriptionText className='helpDesc'>
            {ukrainehelpDescription}
          </HelpDescriptionText>
        </CauseHelpDescriptionGrid>
      </CustomGrid>
      <CauseContainer>
        <Container>
          {data
            && data.map((item) => (
              <MapContainer>
                <CauseTypeText className='type'>{item.causeType}</CauseTypeText>
                <CustomLink to={`/charities?cause=${item.causeType}`}>
                  <ImageContainer>
                    <ImageWrapper bgImage={item.causeImage} />
                  </ImageContainer>
                </CustomLink>
                <StyledIconButton
                  icon='east'
                  onClick={() => history.push(`/charities?cause=${item.causeType}`)}
                  variant={MaterialIconTypes.outlined}
                />
                <CauseSupporter className='support'>
                  {item.causeSupporter}
                </CauseSupporter>
                <CauseDescription className='desc'>
                  {item.causeDescription}
                </CauseDescription>
              </MapContainer>
            ))}
        </Container>
      </CauseContainer>
      <StyledOutline
        onClick={() => history.push('/charities')}
        className='allCharity'
      >
        VIEW ALL ORGANISATIONS
      </StyledOutline>
    </CauseTypeWrapper>
  );
};

CauseTypes.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      causeType: PropTypes.string,
      causeImage: PropTypes.string,
      causeSupporter: PropTypes.string,
      causeDescription: PropTypes.string,
    }),
  ),
};

export default CauseTypes;
