import React, { useState, useEffect } from 'react';

import { useLocation, useHistory } from 'react-router-dom';

import CircularProgress from 'ReactKitComponents/Atoms/ProgressBar/CircularProgressBar/CircularProgress';
import Data from 'Resources/Data/CharityData';
import Charities from 'Pages/Charities/Components/Charities/Charities';
import { db } from 'firebase';
import { useAuth } from 'Contexts/AuthContext';
import { doc, setDoc } from 'firebase/firestore';
import { get } from '../../Services/ApiServiceBase';
import dateConverter from '../../Utils/DateUtils';

/** Data orchesting layer of charities component
 * @returns Charity component
 */
const CharitiesContainer = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const { search } = useLocation();
  const history = useHistory();
  const { currentUser } = useAuth();

  const donarId = new URLSearchParams(search).get('jgDonationId');
  const cause = new URLSearchParams(search).get('cause');

  /** gets list of charities from API */
  const getData = async () => {
    setLoading(true);
    const charityData = Data; // do Api call to fetch data
    setData(charityData);
    setLoading(false);
  };

  /** fetches donation data from justgiving */
  const getDonationData = async () => {
    // Todo: fetch api based on donar id
    const donationData = await get(
      `https://api.justgiving.com/3d64b962/v1/donation/${donarId}`,
    );
    return donationData.data;
  };

  useEffect(() => {
    getData();
  }, []);

  const getCharityDetails = async (id) => {
    setLoading(true);
    const CharityData = await get(
      `https://api.justgiving.com/3d64b962/v1/charity/${id}`,
    );
    setLoading(false);
    return CharityData.data.name;
  };

  /** adds a document of donation data in firestore */
  const setDocument = async () => {
    const donationData = await getDonationData();
    await setDoc(doc(db, `users/${currentUser.uid}/donations`, donarId), {
      id: donarId,
      date: dateConverter(donationData.donationDate),
      charityName: await getCharityDetails(donationData.charityId),
      charityId: donationData.charityId,
      amount: `${donationData.currencyCode} ${donationData.amount}`,
      status: donationData.status,
    });
  };

  /** if redirected after donation from charity portal,
   * donarId is extacted from search params and
   * after updating firestore, redirected to my-donations page */
  if (donarId) {
    setDocument();
    history.replace(`/thankyou?jgDonationId=${donarId}`);
  }

  /** filters data based on cause if available */
  const filter = () => {
    if (cause) {
      return data.filter((charity) => charity.supportedCauses.includes(cause));
    }
    return data;
  };

  return loading ? (
    <CircularProgress />
  ) : (
    <Charities cause={cause || null} data={filter()} />
  );
};

export default CharitiesContainer;
