import { styled } from '@mui/system';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

export const StyledButton = styled(Button)((props) => ({
  margin: props.theme.spacing(1),
}));

export const StyledIconButton = styled(IconButton)((props) => ({
  margin: props.theme.spacing(1),
  borderRadius: 8,
}));
