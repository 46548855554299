import React from 'react';

import CustomTypography from 'ReactKitComponents/Atoms/Typography/CustomTypography';
import BaseLayout from 'Components/Layout/BaseLayout';
import DonationsTableContainer from 'Containers/MyDonations/DonationsTableContainer';
import { Wrapper, Container } from './Styles';

/**
 * @returns My Donations page content with title , desc & table
 */
const Donations = () => (
  <Wrapper>
    <BaseLayout bg>
      <Container>
        <CustomTypography className='mb-16'>My Donations</CustomTypography>
        <DonationsTableContainer />
      </Container>
    </BaseLayout>
  </Wrapper>
);

export default Donations;
