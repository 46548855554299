import React from 'react';

import PropTypes from 'prop-types';

import { Toolbar } from '@mui/material';

/** [Toolbar] sets the properties of the children to make them horizontally aligned.
 * @constructor
 * @param {string} variant - [regular/dense] variant to use
 * @param {bool} disableGutters - If true, disables gutter padding.
 * @param {string} component - component type used for the root node like 'div'.
 */
const CustomToolbar = (props) => {
  const {
    variant, disableGutters, component, children, sx, classes, ...rest
  } = props;
  return (
    <Toolbar
      variant={variant}
      disableGutters={disableGutters}
      component={component}
      sx={sx}
      classes={classes}
      {...rest}
    >
      {children}
    </Toolbar>
  );
};

export default CustomToolbar;

CustomToolbar.propTypes = {
  variant: PropTypes.oneOf(['regular', 'dense']),
  component: PropTypes.string,
  disableGutters: PropTypes.bool,
  children: PropTypes.node,
  sx: PropTypes.shape({}),
  classes: PropTypes.shape({}),
};

CustomToolbar.defaultProps = {
  variant: 'regular',
  component: 'div',
  disableGutters: false,
};
