import React, { useState, useEffect } from 'react';

import CauseTypes from 'Pages/Landing/Components/CauseTypes/CauseTypes';
import causeData from 'Resources/Data/CauseType';

const CauseTypeContainer = () => {
  const [data, setData] = useState();
  useEffect(() => {
    const getData = causeData;
    setData(getData);
  }, []);
  return <CauseTypes data={data} />;
};

export default CauseTypeContainer;
