/** Enum to specify the variant of the icon to be used in CustomMaterialIcon widget */
const MaterialIconTypes = {
  outlined: '-outlined',
  filled: '',
  rounded: '-round',
  sharp: '-sharp',
  twoToned: '-two-tone',
};
Object.freeze(MaterialIconTypes);

export default MaterialIconTypes;
