import { styled } from '@mui/system';

import thankBanner from 'assets/Images/BannerImages/thankBanner.png';
import CustomTypography from 'ReactKitComponents/Atoms/Typography/CustomTypography';
import PaperBlock from 'ReactKitComponents/Atoms/PaperBlock/PaperBlock';
import RaisedButton from 'ReactKitComponents/Atoms/Buttons/StandardButtons/RaisedButton';

export const BannerImg = styled('div')(() => ({
  backgroundImage: `url(${thankBanner})`,
  backgroundSize: '100%',
  backgroundRepeat: 'no-repeat',
}));

export const Wrapper = styled('div')(() => ({
  maxWidth: '1300px',
  margin: '0 auto',
  '@media (min-width:900px) and (max-width:1400px)': {
    marginRight: '64px',
    marginLeft: '64px',
  },
  ' .title': {
    fontSize: '64px',
    lineHeight: '64px',
    fontWeight: 500,
  },
}));

export const StyledButton = styled(RaisedButton)(({ theme }) => ({
  backgroundColor: theme.visualization.yellow,
  color: theme.fontColors.black,
  margin: 0,
  borderRadius: '20px',
  marginTop: '32px',
  ':hover': {
    backgroundColor: '#FF9900',
  },
}));

export const Container = styled('div')(({ theme }) => ({
  backgroundColor: theme.fontColors.black,
  width: '100%',
}));

export const BannerWrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.fontColors.black,
  maxWidth: '1300px',
  margin: '0 auto',
  '@media (min-width:900px) and (max-width:1400px)': {
    marginRight: '64px',
    marginLeft: '64px',
  },
  paddingBottom: '108px',
  '& .MuiBox-root:first-child': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiCircularProgress-root': {
      left: '45%',
    },
  },
}));

export const TransactionBlock = styled(PaperBlock)(({ theme }) => ({
  padding: theme.spacing(4, 8, 4, 8),
  '& .donate': {
    fontSize: '16px',
    lineHeight: '23.04px',
  },
  '& .name': {
    fontSize: '24px',
    lineHeight: '28px',
    fontWeight: 500,
    marginTop: '8px',
  },
  '& .country': {
    fontSize: '16px',
    lineHeight: '20px',
    marginTop: '8px',
  },
  '& .amount': {
    fontSize: '64px',
    lineHeight: '72px',
    fontWeight: 400,
  },
  '& .id': {
    fontSize: '32px',
    lineHeight: '36px',
  },
  '& .one': {
    fontSize: '24px',
    lineHeight: '28px',
    marginLeft: '16px',
  },
}));

export const TrackBlock = styled(PaperBlock)(({ theme }) => ({
  padding: theme.spacing(4, 8, 3, 8),
  backgroundColor: '#FFF7E9',
  borderTop: '1px dashed #000000',
  marginBottom: '108px',
  '& .donate': {
    fontSize: '16px',
    lineHeight: '23.04px',
  },
  '& .desc': {
    fontSize: '18px',
    lineHeight: '22px',
    marginTop: '8px',
  },
  '& .button': {
    fontSize: '18px',
    lineHeight: '25.92px',
    fontWeight: 400,
    boxShadow: 'none',
    paddingBottom: '0px',
  },
}));

export const TransactionTitle = styled(CustomTypography)(({ theme }) => ({
  color: theme.background.white,
  marginTop: '100px',
  marginBottom: '80px',
  maxWidth: '758px',
}));

export const TransactionId = styled(CustomTypography)(() => ({
  marginTop: '16px',
}));

export const Divider = styled('div')(() => ({
  borderBottom: '0.5px solid #000000',
  marginTop: '24px',
  marginBottom: '24px',
}));
