import React from 'react';

import PropTypes from 'prop-types';

import ListItem from '@mui/material/ListItem';
/**
 * @param  {} children - children components
 */
const CustomListItem = (props) => {
  const {
    children, sx, classes, ...rest
  } = props;
  return (
    <ListItem sx={sx} classes={classes} {...rest}>
      {children}
    </ListItem>
  );
};

CustomListItem.propTypes = {
  children: PropTypes.node.isRequired,
  sx: PropTypes.shape({}),
  classes: PropTypes.shape({}),
};

export default CustomListItem;
