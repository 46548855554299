import { styled } from '@mui/system';

import PaperBlock from 'ReactKitComponents/Atoms/PaperBlock/PaperBlock';
import CustomTextField from 'ReactKitComponents/Atoms/InputComponents/TextField/TextField';
import RaisedButton from 'ReactKitComponents/Atoms/Buttons/StandardButtons/RaisedButton';
import CustomTypography from 'ReactKitComponents/Atoms/Typography/CustomTypography';
import IconButton from 'ReactKitComponents/Atoms/Buttons/StandardButtons/IconButton';
import Link from 'ReactKitComponents/Atoms/Link/Link';
import CustomAlert from 'ReactKitComponents/Atoms/Alerts/CustomAlert';

export const StyledPaper = styled(PaperBlock)(({ theme }) => ({
  padding: theme.spacing(10, 1),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '@media (max-width:600px)': {
    padding: theme.spacing(4, 1),
  },
}));

export const StyledForm = styled('form')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

export const StyledInput = styled(CustomTextField)(({ theme }) => ({
  height: theme.spacing(7),
  boxSizing: 'border-box',
  maxWidth: '300px',
  '@media (max-width:350px)': {
    minWidth: '250px',
  },
  '& .MuiFormHelperText-root': {
    ...theme.typography.body2,
  },
}));

export const StyledButton = styled(RaisedButton)(({ theme }) => ({
  margin: theme.spacing(0),
  marginTop: theme.spacing(4.5),
  minWidth: '158px',
  '@media (max-width:600px)': {
    minWidth: '98px',
  },
}));

export const Title = styled(CustomTypography)(({ theme, ...props }) => ({
  marginBottom: props.alert ? theme.spacing(4) : theme.spacing(8),
}));

export const HelperText = styled(CustomTypography)(({ theme, ...props }) => ({
  marginBottom: theme.spacing(4),
  maxWidth: '300px',
  width: '100%',
  paddingLeft: theme.spacing(1),
  ...theme.typography.body2,
  color: props.error ? theme.palette.error.main : theme.visualization.grey,
  '@media (max-width:350px)': {
    maxWidth: '250px',
  },
}));

export const StyledIcon = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(0),
}));

export const StyledText = styled(CustomTypography)(({ theme }) => ({
  marginTop: theme.spacing(7.5),
  ...theme.typography.body2,
  color: theme.visualization.grey,
}));

export const SignUp = styled(Link)(() => ({
  display: 'inline',
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'right',
  justifyContent: 'right',
  width: '100%',
  position: 'relative',
  top: `-${theme.spacing(4)}`,
  '@media (max-width:600px)': {
    top: `-${theme.spacing(4)}`,
  },
}));

export const Alert = styled(CustomAlert)(({ theme }) => ({
  width: '300px',
  boxSizing: 'border-box',
  '@media (max-width:350px)': {
    maxWidth: '250px',
  },
  marginBottom: theme.spacing(2),
  color: '#fff',
}));
