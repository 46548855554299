import React from 'react';

import PropTypes from 'prop-types';

import CustomGrid from 'ReactKitComponents/Atoms/Grid/CustomGrid';
import CustomTypography from 'ReactKitComponents/Atoms/Typography/CustomTypography';
import CharityCard from '../CharityCard/CharityCard';
import Banner from '../Banner/Banner';
import { CardWrapper, ContentWrapper } from './Styles';

/**
 * @param {data} props - Array of charities
 * @returns a list of charity cards
 */
const Charities = (props) => {
  const { data, cause } = props;
  return (
    <Banner>
      <ContentWrapper>
        <CardWrapper>
          <CustomTypography sx={{ mb: 3 }} className='allCharity'>
            {cause ? `Charities that fight ${cause}` : 'All Charities'}
          </CustomTypography>
          <CustomGrid container spacing={3}>
            {data.map((charity, index) => (
              <CustomGrid item md={6}>
                <CharityCard key={index.toString()} data={charity} />
              </CustomGrid>
            ))}
          </CustomGrid>
        </CardWrapper>
      </ContentWrapper>
    </Banner>
  );
};

Charities.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  cause: PropTypes.string,
};

export default Charities;
