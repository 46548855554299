import { styled } from '@mui/system';
import Paper from '@mui/material/Paper';

import CustomTypography from 'ReactKitComponents/Atoms/Typography/CustomTypography';

export const StyledPaper = styled(Paper)((props) => ({
  '&.MuiPaper-rounded': {
    borderRadius: `${props.rounded}px`,
  },
}));

export const Wrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(3),
}));

export const Title = styled(CustomTypography)(({ theme }) => ({
  ...theme.typography.h3,
  marginBottom: theme.spacing(1),
}));

export const Description = styled(CustomTypography)(({ theme }) => ({
  ...theme.typography.h6,
  fontWeight: theme.typography.body2,
  marginBottom: theme.spacing(3),
}));

export const Content = styled(CustomTypography)(({ theme }) => ({
  ...theme.typography.body1,
  color: theme.visualization.grey,
}));
