import React from 'react';

import PropTypes from 'prop-types';

import StyledMaterialIcon from './style';

/**
 * Will be used to display material icons of any variant
 *
 * @param  {} variant - type of icon (Use MaterialIconType Enum to specify)
 * @param  {} icon - ionName (Use https://fonts.google.com/icons to get the icons)
 * @param  {} baseClassName - The base class applied to the icon that suits the icon
 *                            font you're using (e.g. material-icons-rounded, fas, etc).
 * @param  {} color - The color of the component
 * @param  {} fontSize - The fontSize applied to the icon
 */
const CustomMaterialIcon = (props) => {
  const {
    variant, baseClassName, icon, color, fontSize, ...rest
  } = props;

  return (
    <StyledMaterialIcon
      baseClassName={`material-icons${variant}`}
      color={color}
      fontSize={fontSize}
      {...rest}
    >
      {icon}
    </StyledMaterialIcon>
  );
};

CustomMaterialIcon.propTypes = {
  variant: PropTypes.string,
  customClass: PropTypes.string,
  baseClassName: PropTypes.string,
  color: PropTypes.string,
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.string,
};
export default CustomMaterialIcon;
