import React from 'react';

import PropTypes from 'prop-types';

import CustomTypography from 'ReactKitComponents/Atoms/Typography/CustomTypography';
import { quote } from 'Resources/Data/CharitiesScreenData';
import { charityFinder } from 'Resources/Strings';
import { Wrapper, BannerContent, StyledPaper } from './Style';

/**
 * @returns Banner with bg image with content & a paper block that wraps children
 */
const Banner = ({ children }) => (
  <Wrapper>
    <BannerContent>
      <CustomTypography className='finder' component='div'>
        {charityFinder}
      </CustomTypography>
      <CustomTypography className='quote' variant='h1'>
        {quote}
      </CustomTypography>
    </BannerContent>
    <StyledPaper>{children}</StyledPaper>
  </Wrapper>
);

export default Banner;

Banner.propTypes = {
  children: PropTypes.node.isRequired,
};
