const colorPalette = {
  colors: {
    primary: {
      light: '#F7F8Fb',
      main: '#0062FF',
      dark: '#012658',
      contrastText: '#FFFFFF',
    },
    secondary: {
      light: '#F73378',
      main: '#F50057',
      dark: '#AB003C',
      contrastText: '#FFFFFF',
    },
  },
  fontColors: {
    primary: '#00000',
    secondary: '#FFFFFF',
    disabled: '#BBBBBB',
    disabledLight: '#E1E1E1',
    black: '#000000',
  },
  background: {
    cloudGrey: '#E5E5E5',
    white: '#FFFFFF',
    black: '#171725',
  },
  visualization: {
    grey: '#626E82',
    blue: '#0062FF',
    green: '#79D765',
    lavendar: '#8A6FED',
    yellow: '#FFC542',
    sky: '#0094FF',
    pink: '#F97AAF',
    aqua: '#31D0AC',
    aquaDark: '#27A68A',
    silver: '#C2C2C2',
    transparent: 'transparent',
  },
  boxShadow: {
    black: '#000000',
  },
  error: {
    main: '#E97077',
    contrastText: '#FFFFFF',
  },
  warning: {
    main: '#FF6711',
    contrastText: '#FFFFFF',
  },
  info: {
    main: '#0094FF',
    contrastText: '#FFFFFF',
  },
  success: {
    main: '#79D765',
    contrastText: '#FFFFFF',
  },
};

export default colorPalette;
