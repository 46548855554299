import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import rtl from 'jss-rtl';
import { create } from 'jss';

import Routes from 'Routes/Routes';
import store from 'Redux/Store';
import GlobalTheme from 'Themes/GlobalTheme';
import { jssPreset, StylesProvider } from '@mui/styles';
import { ThemeProvider } from '@mui/material/styles';
import AuthProvider from 'Contexts/AuthContext';

const jss = create({
  plugins: [...jssPreset().plugins, rtl()],
});
function App() {
  return (
    <AuthProvider>
      <Provider store={store}>
        <ThemeProvider theme={GlobalTheme}>
          <StylesProvider jss={jss}>
            <BrowserRouter>
              <Routes />
            </BrowserRouter>
          </StylesProvider>
        </ThemeProvider>
      </Provider>
    </AuthProvider>
  );
}

export default App;
