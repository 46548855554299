import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';

import { useAuth } from 'Contexts/AuthContext';
import { loadState } from 'LocalStorage';
import AuthDialog from 'Components/AuthPopUp/AuthDialog';
import CustomTypography from 'ReactKitComponents/Atoms/Typography/CustomTypography';
import { RedirectPassword, supported, funds } from 'Resources/Strings';
import CustomGrid from 'ReactKitComponents/Atoms/Grid/CustomGrid';
import Avatar from 'Components/Avatar/Avatar';
import LinearStatic from 'ReactKitComponents/Atoms/ProgressBar/LinearProgressBar/LinearProgress';
import PercentageCalculator from 'Utils/PercentageUtils';
// import CustomMaterialIcon from
// 'ReactKitComponents/Atoms/Icons/CustomMaterialIcon/CustomMaterialIcon';
// import MaterialIconTypes from 'ReactKitComponents/Enum/MaterialIconTypes';
import {
  StyleCard,
  Divider,
  StyledOutlined,
  StyledRaised,
  TextWrapper,
} from './Styles';

/**
 * @param {data} props - charity data
 * @returns a Charity Card with name, location, causes
 */
const CharityCard = (props) => {
  const { data } = props;
  const [showAuthModal, setshowAuthModal] = useState(false);
  const { currentUser } = useAuth();
  const history = useHistory();

  /** If user loggedin, redirects to charity portal else redirects to Login page */
  const donateHandler = () => {
    if (currentUser) {
      window.open(data.link, '_self');
    } else if (loadState('redirected') === RedirectPassword) {
      setshowAuthModal(true);
    } else {
      setshowAuthModal(true);
    }

    return true;
  };

  const handleModal = () => setshowAuthModal(false);

  return (
    <>
      <StyleCard>
        <CustomGrid container spacing={4}>
          <CustomGrid item xs={6} md={6}>
            <Avatar
              image={data.avatar}
              charityName={data.name}
              location={data.location}
            />
          </CustomGrid>
          <CustomGrid item xs={6} md={6}>
            <TextWrapper>
              <CustomTypography className='support'>
                {supported}
              </CustomTypography>
              {data.supportedCauses.map((cause, index, array) => (
                <CustomTypography className='cause'>
                  {cause}
                  {index === array.length - 1 ? '' : ','}
                  &nbsp;
                </CustomTypography>
              ))}
            </TextWrapper>
          </CustomGrid>
        </CustomGrid>
        {/* <CustomMaterialIcon
          className='fav'
          variant={MaterialIconTypes.outlined}
          icon='favorite_border'
        /> */}
        <Divider />
        <CustomGrid container spacing={4}>
          <CustomGrid item xs={6} md={6}>
            <CustomTypography className='funds'>{funds}</CustomTypography>
            <CustomTypography className='amount'>
              £
              {data.fundsRaised}
              {' '}
              / £1,000,000
            </CustomTypography>
            <LinearStatic
              value={PercentageCalculator(data.fundsRaised)}
              variant='determinate'
            />
          </CustomGrid>
          <CustomGrid item xs={6} md={6}>
            <StyledOutlined onClick={() => history.push('/charities')}>
              Know More
            </StyledOutlined>
            <StyledRaised onClick={donateHandler} size='medium'>
              Donate Now
            </StyledRaised>
          </CustomGrid>
        </CustomGrid>
      </StyleCard>
      {showAuthModal && (
        <AuthDialog handleModal={handleModal} link={data.link} />
      )}
    </>
  );
};

CharityCard.propTypes = {
  data: PropTypes.shape({
    avatar: PropTypes.string,
    name: PropTypes.string,
    location: PropTypes.string,
    supportedCauses: PropTypes.arrayOf(PropTypes.string),
    link: PropTypes.string,
    fundsRaised: PropTypes.string,
  }),
};

export default CharityCard;
