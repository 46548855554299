const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

/**
 * function to convert the parsed date to 'Month date, Year' format
 * ex => toTitleCase(hello world) = Hello World
 */
const dateConverter = (str) => {
  const date = new Date(parseInt(str.substring(6, 24), 10));
  const convertedDate = `${months[date.getMonth() + 1]} ${date.getDay()}, ${date.getFullYear()}`;
  return convertedDate;
};

export default dateConverter;
