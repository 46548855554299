import TimesNewlogo from 'assets/Images/BannerImages/timesnew.png';
import GoogleLogo from 'assets/Images/BannerImages/google.png';
import BloomberLogo from 'assets/Images/BannerImages/bloomberg.png';

const newsData = [
  {
    civilianNumber: '2665',
    newsLogo: TimesNewlogo,
    description: 'civilian casualties since Feb 24, 2022',
  },
  {
    civilianNumber: 'More than 3.9 million',
    newsLogo: GoogleLogo,
    description: 'civilian casualties since Feb 24, 2022',
  },
  {
    civilianNumber: 'More than 8 billion',
    newsLogo: BloomberLogo,
    description: 'US assistance sent to Ukraine over the past year',
  },
];

export default newsData;
