import { styled } from '@mui/system';

import CustomGrid from 'ReactKitComponents/Atoms/Grid/CustomGrid';
import CustomTypography from 'ReactKitComponents/Atoms/Typography/CustomTypography';
import OutlinedButton from 'ReactKitComponents/Atoms/Buttons/StandardButtons/OutlinedButton';
import PaperBlock from 'ReactKitComponents/Atoms/PaperBlock/PaperBlock';

export const NewsWrapper = styled('div')(() => ({
  '& .donateButton': {
    fontSize: '24px',
    lineHeight: '34.56px',
    fontWeight: 400,
    paddingBottom: '9px',
  },
  '& .number': {
    fontWeight: 500,
    fontSize: '32px',
    lineHeight: '32px',
    width: '198px',
    marginTop: '-20px',
  },
  '& .description': {
    fontSize: '18px',
    lineHeight: '24.59px',
    fontWeight: 400,
    width: '100%',
  },
  '& .million': {
    marginLeft: '385px',
    marginTop: '179px',
    '@media (max-width: 1400px)': {
      marginLeft: '340px',
    },
  },
  '& .billion': {
    marginTop: '41px',
  },
}));

export const StyledNewsGrid = styled(CustomGrid)(() => ({}));

export const StyledPaper = styled(PaperBlock)(() => ({
  padding: '16px',
  ':nth-child(1)': { width: '205px' },
  ':nth-child(2)': { width: '200px' },
  ':nth-child(3)': { width: '240px' },
  opacity: '0.68',
  img: {
    marginLeft: '-45px',
    marginTop: '-45px',
    height: '68px',
    width: '68px',
  },
}));

export const NewsCardGrid = styled(CustomGrid)(() => ({ marginTop: '163px' }));

export const NewsTextGrid = styled(CustomGrid)(() => ({
  '& .letUs': {
    fontSize: '96px',
    lineHeight: '90px',
    fontWeight: 400,
    marginTop: '209px',
    marginLeft: '55px',
    '@media (max-width: 1400px)': {
      marginLeft: '125px',
    },
  },
  '& .helpDesc': {
    fontSize: '18px',
    lineHeight: '24.59px',
    fontWeight: 500,
    marginLeft: '170px',
  },
}));

export const NewsDiv = styled('div')(() => ({
  ':nth-child(2)': {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  ':nth-child(2) > img': {
    paddingRight: '180px',
  },
}));

export const NewsLogoImg = styled('img')(() => ({
  position: 'absolute',
  zIndex: 1,
}));

export const NewsPaperBlock = styled(PaperBlock)(() => ({
  padding: '16px',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  top: 40,
  left: 42,
  marginBottom: '179px',
  ':nth-child(2)': {
    marginBottom: '41px',
  },
  opacity: '0.76',
}));

export const CivilianNoTypography = styled(CustomTypography)(() => ({
  marginBottom: '8px',
}));

export const DescriptionTypography = styled(CustomTypography)(() => ({}));

export const LetUsStandText = styled(CustomTypography)(({ theme }) => ({
  color: theme.background.white,
}));

export const InfoText = styled(CustomTypography)(({ theme }) => ({
  marginTop: '32px',
  marginLeft: '100px',
  flexWrap: 'wrap',
  color: theme.background.white,
}));

export const NewsDonateButton = styled(OutlinedButton)(({ theme }) => ({
  width: '271px',
  marginTop: '16px',
  paddingTop: '16px',
  marginLeft: '170px',
  paddingBottom: '16px',
  color: theme.background.black,
  backgroundColor: '#FEB80A',
  borderRadius: '50px',
  border: 'none',
  '&.MuiButtonBase-root:hover': {
    border: 'none',
    backgroundColor: '#FF9900',
  },
}));
