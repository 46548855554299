import { styled } from '@mui/system';

export const Wrapper = styled('div')(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  boxSizing: 'border-box',
  '& .MuiPaper-root': {
    borderRadius: 0,
  },
}));

export const CardWrapper = styled('div')(() => ({
  maxWidth: '1300px',
  margin: '0 auto',
  '& .allCharity': {
    fontSize: '32px',
    fontWeight: 400,
    lineHeight: '43.71px',
    marginTop: '40px',
  },
}));

export const ContentWrapper = styled('div')(() => ({}));
