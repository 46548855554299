import aamha from 'assets/Images/BannerImages/aamha.png';

const data = [
  {
    name: 'Aamha Helping Hands Org.',
    location: 'Kiev, Ukraine',
    supportedCauses: ['Child Support'],
    fundsRaised: '234,5697',
    link:
      'http://link.justgiving.com/v1/charity/donate/charityId/1847149?amount=2.00&currency=USD&tipScheme=TipJar2.1&forceGuest=true&reference=mr&exitUrl=https%3A%2F%2Fstandsforhumanity.com%2Fcharities%3FjgDonationId%3DJUSTGIVING-DONATION-ID&message=donation%20for%20ukarine',
    avatar: aamha,
  },
  {
    name: 'Mission Ukraine',
    location: 'Newyork, USA',
    supportedCauses: ['Disaster Relief', 'Refugee Shelter'],
    fundsRaised: '100,5400',
    link:
      'http://link.justgiving.com/v1/charity/donate/charityId/1847149?amount=2.00&currency=USD&tipScheme=TipJar2.1&forceGuest=true&reference=mr&exitUrl=https%3A%2F%2Fstandsforhumanity.com%2Fcharities%3FjgDonationId%3DJUSTGIVING-DONATION-ID&message=donation%20for%20ukarine',
    avatar: aamha,
  },
  {
    name: 'United help Ukraine',
    location: 'Kiev, Ukraine',
    supportedCauses: ['Disaster Relief', 'Child Support', 'Poverty'],
    fundsRaised: '914,1950',
    link: 'http://link.justgiving.com/v1/charity/donate/charityId/1847149?amount=2.00&currency=USD&tipScheme=TipJar2.1&forceGuest=true&reference=mr&exitUrl=https%3A%2F%2Fstandsforhumanity.com%2Fcharities%3FjgDonationId%3DJUSTGIVING-DONATION-ID&message=donation%20for%20ukarine',
    avatar: aamha,
  },
  {
    name: 'Aamha Org',
    location: 'London, UK',
    supportedCauses: ['Child Support', 'Medical-aid'],
    fundsRaised: '99,9090',
    link: '/charities?jgDonationId=2357',
    avatar: aamha,
  },
];

export default data;
