import React from 'react';

import CharitiesContainer from 'Containers/Charities/CharitiesContainer';
import BaseLayout from 'Components/Layout/BaseLayout';
import { BannerImg } from './Components/Banner/Style';

/**
 * @returns Charities page containing Banner & List of charity cards
 */
const Charities = () => (
  <BannerImg>
    <BaseLayout>
      <CharitiesContainer />
    </BaseLayout>
  </BannerImg>
);

export default Charities;
