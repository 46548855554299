import React from 'react';

import PropTypes from 'prop-types';

import CustomToolbar from 'ReactKitComponents/Atoms/Toolbar/CustomToolbar';
import CustomTooltip from 'ReactKitComponents/Atoms/Tooltip/CustomTooltip';
import IconButton from 'ReactKitComponents/Atoms/Buttons/StandardButtons/IconButton';
import MaterialIconTypes from 'ReactKitComponents/Enum/MaterialIconTypes';
import { TableTitle } from 'ReactKitComponents/Molecules/Tables/BasicTable/Style';

/** [TableHeader]
 * The table header component.
 * Display Table Title and Icon.
 * @param {string} tableTitle - Title of the table
 * @param {String} tableIconName - Name of the table icon to be used in the header
 * @param {String} tableIconDesc - Tooltip title for table Icon
 * @param {func} iconCallback - callback fired when the table icon is clicked
 * @return {ReactElement} - The table header component
 */

const TableHeader = (props) => {
  const {
    tableTitle, tableIconName, iconCallback, tableIconDesc, ...rest
  } = props;

  return (
    <>
      <CustomToolbar {...rest}>
        <TableTitle
          variant='h3'
        >
          {tableTitle}
        </TableTitle>
        {tableIconName && (
          <CustomTooltip title={tableIconDesc || tableIconName}>
            <IconButton
              onClick={iconCallback}
              icon={tableIconName}
              variant={MaterialIconTypes.outlined}
              color='primary'
            />
          </CustomTooltip>
        )}
      </CustomToolbar>
    </>
  );
};

TableHeader.propTypes = {
  tableTitle: PropTypes.string,
  tableIconName: PropTypes.string,
  iconCallback: PropTypes.func,
  tableIconDesc: PropTypes.string,
};

TableHeader.defaultProps = {
  iconCallback: () => { },
};

export default TableHeader;
