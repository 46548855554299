import { styled } from '@mui/system';

import Button from '@mui/material/Button';

const StyledButton = styled(Button)((props) => ({
  margin: props.theme.spacing(1),
  padding: `${props.theme.spacing(1)}px ${props.theme.spacing(2)}px`,
  borderRadius: props.borderradius,
  backgroundColor: props.backgroundcolor,
  color: props.fontcolor,
  width: props.width,
  '&:hover': {
    backgroundColor: props.backgroundcolor,
  },
  '&:focus': {
    background: `linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), ${props.backgroundcolor}`,
    border: `${3}px solid ${props.backgroundcolor}`,
  },
}));

export default StyledButton;
