import React from 'react';

import PropTypes from 'prop-types';

import ImageLoader from 'ReactKitComponents/Atoms/ImageLoader/ImageLoader';
import {
  StyledDiv,
  StyledAppbar,
  StyledCustomToolbar,
  StyledCustomLink,
  StyledProfile,
} from './Style';

/** [Header]
 * @constructor
 * @param  {bool} open - bool to open the side drawer
 */

const Header = (props) => {
  const {
    open,
    imageUrl,
    imageAltText,
    logoPath,
    image,
    profileData,
    primary,
    secondary,
    avatarChildren,
    headComponent,
    children,
    profileExist,
    ...rest
  } = props;

  return (
    <StyledDiv {...rest}>
      <StyledAppbar open={open}>
        <StyledCustomToolbar>
          {headComponent && headComponent}
          {imageUrl && (
            <StyledCustomLink open={open} to={logoPath}>
              <ImageLoader image={image} alt={imageAltText} />
            </StyledCustomLink>
          )}
          {children}
          {profileExist ? (
            <StyledProfile
              data={profileData}
              primary={primary}
              secondary={secondary}
              avatarChildren={avatarChildren}
            />
          ) : (
            ''
          )}
        </StyledCustomToolbar>
      </StyledAppbar>
    </StyledDiv>
  );
};

Header.propTypes = {
  logoPath: PropTypes.string,
  open: PropTypes.bool.isRequired,
  profileExist: PropTypes.bool,
  imageUrl: PropTypes.string,
  children: PropTypes.node,
  imageAltText: PropTypes.string,
  image: PropTypes.string,
  profileData: PropTypes.arrayOf(
    PropTypes.shape({
      menuIcon: PropTypes.string,
      menuIconVariant: PropTypes.string,
      primary: PropTypes.string,
      secondary: PropTypes.string,
      link: PropTypes.string,
    }),
  ),
  avatarChildren: PropTypes.node,
  primary: PropTypes.string,
  secondary: PropTypes.string,
  headComponent: PropTypes.node,
};

export default Header;
