import React from 'react';

import PropTypes from 'prop-types';

import { useAuth } from 'Contexts/AuthContext';
import CustomTypography from 'ReactKitComponents/Atoms/Typography/CustomTypography';
import { StyledTable, Wrapper } from './Styles';

/**
 * @param {columns} props - table columns data source
 * @param {dataSource} props - table rows data source
 * @returns a table of donations data
 */
const Table = (props) => {
  const { columns, dataSource } = props;
  const noOfDonations = dataSource.length;
  const { currentUser } = useAuth();
  return noOfDonations ? (
    <Wrapper>
      <CustomTypography variant='h6' className='mb-48'>
        Showing
        {' '}
        <span className='highlight'>
          {dataSource.length}
          {' '}
          result
          {dataSource.length > 1 && 's'}
        </span>
        {' '}
        associated with
        {' '}
        <span className='highlight'>{currentUser.email}</span>
      </CustomTypography>
      <StyledTable
        size='medium'
        className='mb-48'
        columns={columns}
        dataSource={dataSource}
      />
    </Wrapper>
  ) : (
    <CustomTypography variant='h5' className='mb-48'>
      No Donations Found !
    </CustomTypography>
  );
};

export default Table;

Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  dataSource: PropTypes.arrayOf(PropTypes.shape({})),
};
