import React from 'react';

import { TransactionTitle } from '../style';

const Greeting = () => (
  <TransactionTitle className='title'>
    Transaction successful. We appreciate your generous support!
  </TransactionTitle>
);

export default Greeting;
