import { styled } from '@mui/system';
import { Link } from 'react-router-dom';

const StyledLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'hoverColor',
})((props) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  textDecoration: 'none',
  color: props.color ?? props.theme.palette.primary.main,
  '&:hover': {
    color: props.hoverColor ?? props.theme.palette.info.main,
  },
}));

export default StyledLink;
