import { styled } from '@mui/system';
import Refugee from 'assets/Images/BannerImages/Ukrainewar.png';

export const Wrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 8, 8, 8),
  marginBottom: '90px',
  maxWidth: '1300px',
  margin: '0 auto',
  '@media (max-width: 768px)': {
    padding: theme.spacing(0, 2, 8, 2),
  },
  '& .allCharity': {
    fontSize: '16px',
    lineHeight: '23.04px',
    fontWeight: 500,
    color: theme.background.black,
  },
}));

export const NewsInfoMainWrapper = styled('div')(() => ({
  paddingBottom: '22px',
}));

export const BannerImg = styled('div')(() => ({
  width: '100%',
  height: '920px',
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
  backgroundImage: `url(${Refugee})`,
}));
