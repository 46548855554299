import React from 'react';

import PropTypes from 'prop-types';

import { Wrapper, Name, Location } from './styles';

const Avatar = (props) => {
  const { charityName, location } = props;
  return (
    <Wrapper>
      <div>
        <Name>{charityName}</Name>
        <Location className='location'>{location}</Location>
      </div>
    </Wrapper>
  );
};

export default Avatar;

Avatar.propTypes = {
  charityName: PropTypes.string,
  location: PropTypes.string,
};
