import { styled } from '@mui/system';

import LinearProgress from '@material-ui/core/LinearProgress';

const StyledLinearProgress = styled(LinearProgress)(() => ({
  width: '100%',
  flexGrow: 1,
  '& .MuiLinearProgress-root': {
    borderRadius: '5px',
  },
  '& .MuiLinearProgress-bar': {
    borderRadius: '5px',
  },
}));

export default StyledLinearProgress;
