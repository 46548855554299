import React from 'react';

import PropTypes from 'prop-types';
import AlertTitle from '@mui/material/AlertTitle';

import StyledAlert from './Style';

/** [Custom Alert] is a common component that is used to Display Alerts
 * @constructor
 * @param {string} severity     - Defines the type of Alert (Enum AlertSeverityType).
 * @param {string} variant      - Defines the Variant of Alert (Enum AlertVariantType).
 * @param {string} alertTitle   - Title for Alert.
 * @param {string} children     - Message to Be displayed on Alert Box.
 * @param {node/bool} icon         - Defines Type of Icon To be used.
 *                              - Pass false to remove Icon.
 * @param {node} actiontype     - Defines the component to be displayed for action.
 *                                (icon to close alert, Display Message)
 *                                will be displayed on right side of Alert box
 * @param {string} closeText   - Override the default label for the close popup icon button.
 * @param {string} color   - The main color for the alert.
 * Unless provided, the value is taken from the severity prop.
 * @param {object} sx   - The system prop that allows defining system
 * overrides as well as additional CSS styles.
 */

const CustomAlert = React.forwardRef((props, ref) => {
  const {
    children,
    severity,
    variant,
    alertTitle,
    actiontype,
    icon,
    closeText,
    color,
    sx,
    ...rest
  } = props;

  return (
    <StyledAlert
      ref={ref}
      severity={severity}
      variant={variant}
      icon={icon}
      action={actiontype}
      closeText={closeText}
      color={color}
      sx={sx}
      {...rest}
    >
      <AlertTitle>{alertTitle}</AlertTitle>
      {children}
    </StyledAlert>
  );
});

CustomAlert.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.instanceOf(Object),
  ]),
  severity: PropTypes.string,
  variant: PropTypes.string,
  alertTitle: PropTypes.string,
  closeText: PropTypes.string,
  color: PropTypes.string,
  sx: PropTypes.shape({}),
  icon: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.node,
    PropTypes.instanceOf(Object),
  ]),
  actiontype: PropTypes.node,
};

CustomAlert.defaultProps = {
  children: 'Alert',
};

export default CustomAlert;
