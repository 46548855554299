import React from 'react';

import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

/** [CustomBox] component serves as a wrapper component for most of the CSS utility needs.
 * @constructor
 * @param {Object} sx - allows defining system overrides as well as additional CSS styles.
 * @param {string} children - content of the component.
 * @param {string,func,object} component - The component used for the root node.
 * Either a string to use a DOM element or a component.
*/
const CustomBox = (props) => {
  const {
    component, children, sx, ...rest
  } = props;

  return (
    <Box component={component} sx={sx} {...rest}>
      {children}
    </Box>
  );
};

CustomBox.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.shape({}),
  ]),
  children: PropTypes.node,
  sx: PropTypes.shape({}),
};

CustomBox.defaultProps = {
  component: 'div',
  sx: {},
};

export default CustomBox;
