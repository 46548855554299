import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const app = initializeApp({
  apiKey: 'AIzaSyCIvKr-972rfiYlTEpYGAW4NFST23hqME8',
  authDomain: 'donar-portal.firebaseapp.com',
  projectId: 'donar-portal',
  storageBucket: 'donar-portal.appspot.com',
  messagingSenderId: '75468898442',
  appId: '1:75468898442:web:8b57bb32252f5eb7996d73',
  measurementId: 'G-7DCLZMM8HC',
});

export const auth = getAuth(app);
export const db = getFirestore(app);
export default app;
