import Dashboard from 'Pages/Landing';
import Login from 'Pages/Login';
import Signup from 'Pages/SignUp/SignUp';
import Charities from 'Pages/Charities';
import ResetPassword from 'Pages/ResetPassword/ResetPassword';
import MyDonations from 'Pages/MyDonations';
import ThankYou from 'Pages/ThankYou';
import Fallback from 'Pages/Fallback';
// Create new routes for components through componentsRouteConfig

export const pagesRouteConfig = [];

export const appRouteConfig = [];

export const componentsRouteConfig = [
  {
    key: 'Dashboard',
    path: '/',
    exact: true,
    auth: false,
    component: Dashboard,
  },
  {
    key: 'Charities',
    path: '/charities',
    exact: true,
    auth: false,
    component: Charities,
  },
  {
    key: 'My Donations',
    path: '/my-donations',
    exact: true,
    auth: false,
    component: MyDonations,
  },
  {
    key: 'Login',
    path: '/login',
    exact: true,
    auth: false,
    component: Login,
  },
  {
    key: 'SignUp',
    path: '/signup',
    exact: true,
    auth: false,
    component: Signup,
  },
  {
    key: 'Reset Password',
    path: '/reset-password',
    exact: true,
    auth: false,
    component: ResetPassword,
  },
  {
    key: 'Charities',
    path: '/charities',
    exact: true,
    auth: false,
    component: Charities,
  },
  {
    key: 'Thankyou',
    path: '/thankyou',
    exact: true,
    auth: false,
    component: ThankYou,
  },
  {
    key: 'Fallback',
    path: '/fallback',
    exact: true,
    auth: false,
    component: Fallback,
  },
];
