import { styled } from '@mui/system';

import charityBanner from 'assets/Images/BannerImages/charity.png';
import PaperBlock from 'ReactKitComponents/Atoms/PaperBlock/PaperBlock';

export const BannerContent = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 0, 4, 0),
  maxWidth: '1300px',
  '& .MuiPaper-root': {
    borderRadius: 0,
  },
  '@media (min-width:900px) and (max-width:1400px)': {
    marginRight: '64px',
    marginLeft: '64px',
  },
  margin: '0 auto',
  boxSizing: 'border-box',
  '& .quote': {
    maxWidth: 758,
    fontWeight: 500,
    fontSize: '64px',
    lineHeight: '64px',
  },
  '& .banner-desc': {
    maxWidth: 529,
    width: '100%',
    marginTop: 129,
    lineHeight: '24px',
    marginRight: 30,
  },
  '& .finder': {
    fontSize: '18px',
    display: 'block',
    lineHeight: '22px',
    marginBottom: '8px',
    marginTop: '144px',
  },
}));

export const Wrapper = styled('div')(() => ({
  color: '#FFF',
  position: 'relative',
}));

export const BannerImg = styled('div')(() => ({
  backgroundImage: `linear-gradient(270deg, rgba(0, 0, 0, 0.7) 30.26%, rgba(0, 0, 0, 0.35) 73.38%),url(${charityBanner})`,
  backgroundSize: '100%',
  backgroundRepeat: 'no-repeat',
}));

export const StyledDiv = styled('div')(({ theme }) => ({
  backgroundColor: theme.background.white,
  height: '108px',
}));

export const StyledPaper = styled(PaperBlock)(({ theme }) => ({
  padding: theme.spacing(3, 8),
  height: '100%',
  borderBottomLeftRadius: '0 !important',
  borderBottomRightRadius: '0 !important',
  paddingBottom: '108px',
}));
