/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'users',
  initialState: { isLoading: false, error: null, data: [] },
  reducers: {
    loading(state) {
      state.isLoading = true;
    },
    success(state, action) {
      state.isLoading = false;
      state.data = action.payload;
    },
    failed(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const userActions = userSlice.actions;

export default userSlice;
