import { styled } from '@mui/system';

import Alert from '@mui/material/Alert';

const StyledAlert = styled(Alert)(({ theme, ...props }) => {
  const handleColor = () => {
    switch (props.severity) {
      case 'error':
        return theme.palette.error.main;
      case 'success':
        return theme.palette.success.main;
      case 'warning':
        return theme.palette.warning.main;
      case 'info':
        return theme.palette.info.main;
      default:
        return theme.palette.primary.contrastText;
    }
  };

  return {
    ...theme.typography.body1,
    margin: '5px',
    alignItems: 'center',
    padding: theme.spacing(0, 2),
    lineHeight: theme.typography.h4.lineHeight,
    borderRadius: theme.rounded.small,
    background: props.background,
    border: 'none',
    boxShadow: theme.boxShadow.button,
    '& .MuiButton-root': {
      padding: '0',
      margin: ' 0 15px',
      width: 'auto',
      '&:hover': {
        background: theme.palette.primary.contrastText,
        color: theme.palette.primary.main,
      },
      '&:focus': {
        boxShadow: 'none',
      },
    },
    '& .MuiIcon-root': {
      fontSize: theme.typography.h3.fontSize,
    },
    '& .MuiAlert-icon': {
      color: props.iconcolor,
      marginRight: theme.spacing(1),
    },
    '& .MuiAlert-message': {
      marginBottom: '4px',
    },

    color: `${handleColor()}`,

    '& .MuiAlert-action': {
      paddingTop: theme.spacing(0),
      width: 'auto',
      '&:focus-visible': {
        outline: 'none',
      },
    },
  };
});

export default StyledAlert;
