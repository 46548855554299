import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import TransactionDetails from 'Pages/ThankYou/Components/TransactionDetail';
import CircularProgress from 'ReactKitComponents/Atoms/ProgressBar/CircularProgressBar/CircularProgress';
import { get } from '../../Services/ApiServiceBase';

const ThankContainer = () => {
  const { search } = useLocation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [donationDetails, setDonationDetails] = useState([]);
  const donarId = new URLSearchParams(search).get('jgDonationId');

  const getCharityId = async () => {
    setLoading(true);
    const donationData = await get(
      `https://api.justgiving.com/3d64b962/v1/donation/${donarId}`,
    );
    setDonationDetails(donationData.data);
    const { charityId } = donationData.data;
    return charityId;
  };

  const getCharityDetails = async () => {
    setLoading(true);
    const id = await getCharityId();
    const CharityData = await get(
      `https://api.justgiving.com/3d64b962/v1/charity/${id}`,
    );
    setData(CharityData.data);
    setLoading(false);
    return CharityData.data;
  };

  useEffect(() => {
    getCharityDetails();
  }, []);

  return loading ? (
    <CircularProgress />
  ) : (
    <TransactionDetails
      donarId={donarId}
      charityData={data}
      donationDetails={donationDetails}
    />
  );
};

export default ThankContainer;
