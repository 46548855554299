import React from 'react';

import { Switch, Route } from 'react-router-dom';

import Fallback from 'Pages/Fallback';
import ComponentsRoutes from './ComponentsRoutes';

const Routes = () => (
  <Switch>
    <Route path='/' render={() => <ComponentsRoutes />} />
    <Route path='*'>
      <Fallback />
    </Route>
  </Switch>
);

export default Routes;
