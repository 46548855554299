import React, { useState, useEffect } from 'react';

import NewsInfo from 'Pages/Landing/Components/NewsInformation/NewsInfo';
import newsData from 'Resources/Data/NewsInformation';

const NewsInfoContainer = () => {
  const [data, setData] = useState();
  useEffect(() => {
    const getData = newsData;
    setData(getData);
  }, []);
  return <NewsInfo data={data} />;
};

export default NewsInfoContainer;
