import { styled } from '@mui/system';

import CustomTypography from 'ReactKitComponents/Atoms/Typography/CustomTypography';

export const Wrapper = styled('div')(() => ({
  display: 'flex',
  '& .location': {
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 400,
  },
}));

export const Name = styled(CustomTypography)(() => ({
  fontSize: '24px',
  lineHeight: '28px',
  fontWeight: 500,
  marginTop: '8px',
  marginBottom: '8px',
}));

export const Location = styled(CustomTypography)(() => ({}));
