import axios from 'axios';

// Need Base URL
// const baseURL = process.env.REACT_APP_URL;
const baseURL = '';
const headers = {
  'Content-Type': 'application/json;charset=UTF-8',
  /* Once we get the authentications we'll add the tokens */
  // token: '',
};

/* Axios Instance */
const axiosInstance = axios.create({
  baseURL,
  headers,
});
/* Request and Response Interceptor */
axiosInstance.interceptors.request.use(
  (request) => request,
  (error) => Promise.reject(error.response.data),
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error.response.data),
);
/* GET, POST, PUT, DELETE Calls */
export const get = (url, config) => {
  const apiURL = baseURL + url;
  return axiosInstance.get(apiURL, config ?? {});
};

export const post = (url, data, config) => {
  const apiURL = baseURL + url;
  return axiosInstance.post(apiURL, data ?? {}, config ?? {});
};

export const put = (url, data, config) => {
  const apiURL = baseURL + url;
  return axiosInstance.put(apiURL, data ?? {}, config ?? {});
};

export const del = (url, config) => {
  const apiURL = baseURL + url;
  return axiosInstance.delete(apiURL, config ?? {});
};
