import { styled } from '@mui/system';

export const Wrapper = styled('div')(() => ({
  background: '#FFF7E9',
}));

export const AssistText = styled('div')(() => ({
  fontSize: '1rem',
  textTransform: 'uppercase',
}));

export const Container = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 0, 6),
  maxWidth: '1300px',
  margin: '0 auto',
  '@media (min-width:900px) and (max-width:1400px)': {
    marginRight: '64px',
    marginLeft: '64px',
  },
  '& .mb-8': {
    marginBottom: theme.spacing(1),
  },
  '& .mb-16': {
    marginBottom: theme.spacing(2),
    marginTop: '100px',
    fontSize: '48px',
    lineHeight: '52px',
    fontWeight: 400,
  },
  '& .mb-48': {
    marginBottom: theme.spacing(6),
  },
  '& .MuiTableContainer-root': {
    background: 'transparent',
    color: '#000',
  },
  '& .MuiTableCell-root': {
    color: '#000',
  },
  '& .MuiTableCell-root.MuiTableCell-head': {
    fontSize: '1rem',
    lineHeight: '24px',
    fontWeight: 600,
  },
}));
