import React from 'react';

import PropTypes from 'prop-types';

import CustomMaterialIcon from 'ReactKitComponents/Atoms/Icons/CustomMaterialIcon/CustomMaterialIcon';
import MaterialIconTypes from 'ReactKitComponents/Enum/MaterialIconTypes';
import { StyledIconButton } from './Style';

/** [IconButton] is a common component that can be used for a basic IconButton component
 * @constructor
 * @param {string} color - The color of the component.
 * @param {boolean} disabled - whether button is disabled or not
 * @param {node} children - defines icon as a children
 * @param {object} sx - The system prop that allows defining system overrides
 * @param {object} size - The size of the component.
 * @param {string} variant - Variant of the icon.
 * @param {string} icon - Icon to be displayed inside the button.
 */

const IconButton = React.forwardRef((props, ref) => {
  const {
    color, disabled, icon, size, variant, onClick, sx, children, ...rest
  } = props;

  return (
    <StyledIconButton
      ref={ref}
      disabled={disabled}
      color={color}
      onClick={onClick}
      size={size}
      sx={sx}
      {...rest}
    >
      {children ?? <CustomMaterialIcon icon={icon} variant={variant} />}
    </StyledIconButton>
  );
});

IconButton.propTypes = {
  color: PropTypes.oneOf([
    'inherit',
    'default',
    'primary',
    'secondary',
    'error',
    'info',
    'success',
    'warning',
  ]),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  variant: PropTypes.string,
  onClick: PropTypes.func,
  sx: PropTypes.shape({}),
  children: PropTypes.node,
};

IconButton.defaultProps = {
  color: 'default',
  disabled: false,
  icon: 'add',
  variant: MaterialIconTypes.outlined,
  onClick: () => { },
};

export default IconButton;
