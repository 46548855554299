const column = [
  {
    name: 'Date',
    dataIndex: 'Date',
  },
  {
    name: 'Transaction ID',
    dataIndex: 'TransactionID',
  },
  {
    name: 'Charity Name',
    dataIndex: 'Charity Name',
  },
  {
    name: 'Amount',
    dataIndex: 'Amount',
  },
  {
    name: 'Status',
    dataIndex: 'Status',
  },
  {
    name: ' ',
    dataIndex: 'receipt',
  },
];

export default column;
