import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { componentsRouteConfig } from 'Routes/RouteConfig';

const ComponentsRoutes = () => (
  <Switch>
    {componentsRouteConfig.map((route) => (
      <Route
        key={route.key}
        path={route.path}
        exact={route.exact}
        render={() => <route.component />}
      />
    ))}
  </Switch>
);

export default ComponentsRoutes;
