import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useFormik } from 'formik';

// import AlertVariantType from 'ReactKitComponents/Enum/AlertVariantType';
// import AlertSeverityType from 'ReactKitComponents/Enum/AlertSeverityType';
// import CustomCollapse from 'ReactKitComponents/Atoms/Collapse/Collapse';
import CustomTypography from 'ReactKitComponents/Atoms/Typography/CustomTypography';
import {
  StyledPaper,
  StyledForm,
  StyledInput,
  StyledButton,
  Title,
  StyledLink,
  StyledIcon,
  StyledText,
  SignUp,
  HelperText,
  // Alert,
} from './Styles';

/**
 * [AuthenticationForm] is a common component that can be used for creating simple Login/SignUp Form
 * @param {bool} signUp - if true, returns a signUp form
 * @param {string} title - Title of the form.
 * @param {func} submitHandler - func that gets fired when form submitted
 * @param {string} submitLabel - label of submit button
 * @param {object} validationSchema - Object that validates the input fields.
 * @param {object} userProps - object that defines user field (i.e.,)email
 * @param {object} passwordProps - object that defines passwords field
 * @param {object} confirmPasswordProps - object that defines confirm password field
 * @param {object} forgetPasswordProps - defines forget password link, label
 * @param {object} redirectProps - defines link, label ,desc to redirect to signUp /login page
 * @param {object} alertProps - defines the error message after form submition
 */

const AuthenticationForm = (props) => {
  const {
    signUp,
    title,
    submitLabel,
    submitHandler,
    validationSchema,
    userProps,
    passwordProps,
    confirmPasswordProps,
    forgetPasswordProps,
    alertProps,
    redirectProps,
    subTitle,
    ...rest
  } = props;

  const [showPassword, setShowPassword] = useState(false);
  // const [showAlert, setShowAlert] = useState(true);

  const formik = useFormik({
    initialValues: {},
    validationSchema,
    onSubmit: (values) => {
      // setShowAlert(true);
      submitHandler(values);
    },
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // const handleAlert = () => alertProps && setShowAlert(false);

  return (
    <StyledPaper {...rest}>
      <Title variant='h3' component='div' alert={alertProps}>
        {title}
      </Title>
      <Title variant='h5' component='div' alert={alertProps}>
        {subTitle}
      </Title>
      {/* {alertProps && (
        <CustomCollapse orientation='horizontal' in={showAlert}>
          <Alert
            variant={alertProps.variant || AlertVariantType.filled}
            severity={alertProps.severity || AlertSeverityType.error}
          >
            {alertProps.message}
          </Alert>
        </CustomCollapse>
      )} */}
      <StyledForm onSubmit={formik.handleSubmit}>
        <StyledInput
          id={userProps.id || 'email'}
          name={userProps.name || 'email'}
          type={userProps.type || 'email'}
          onChange={formik.handleChange}
          value={formik.values.email ?? ''}
          defaultValue={userProps.defaultValue}
          label={userProps.label || 'Email'}
          error={userProps.error}
          color={userProps.color}
          variant={userProps.variant}
        />
        <HelperText error={userProps.error && 'true'}>
          {userProps.helperText || formik.errors[userProps.name || 'email']}
        </HelperText>
        <StyledInput
          id={passwordProps.id || 'password'}
          name={passwordProps.name || 'password'}
          type={showPassword ? 'text' : 'password'}
          onChange={formik.handleChange}
          value={formik.values.password ?? ''}
          label={passwordProps.label || 'Password'}
          defaultValue={passwordProps.defaultValue}
          error={passwordProps.error}
          color={passwordProps.color}
          variant={passwordProps.variant}
          adornmentPosition='end'
          inputAdornment={(
            <StyledIcon
              aria-label='toggle password visibility'
              onClick={handleClickShowPassword}
              edge='end'
              icon={showPassword ? 'visibility_off' : 'visibility'}
            />
          )}
        />
        <HelperText error={passwordProps.error && 'true'}>
          {passwordProps.helperText
            || formik.errors[passwordProps.name || 'password']}
        </HelperText>
        {!signUp && forgetPasswordProps && (
          <StyledLink
            to={forgetPasswordProps.to}
            target={forgetPasswordProps.target}
          >
            {forgetPasswordProps.label}
          </StyledLink>
        )}
        {signUp && (
          <>
            <StyledInput
              id={confirmPasswordProps.id || 'confirmPassword'}
              name={confirmPasswordProps.name || 'confirmPassword'}
              type='password'
              onChange={formik.handleChange}
              value={formik.values.confirmPassword ?? ''}
              label={confirmPasswordProps.label || 'Password'}
              defaultValue={confirmPasswordProps.defaultValue}
              error={confirmPasswordProps.error}
              color={confirmPasswordProps.color}
              variant={confirmPasswordProps.variant}
            />
            <HelperText error={confirmPasswordProps.error && 'true'}>
              {confirmPasswordProps.helperText
                || formik.errors[confirmPasswordProps.name || 'confirmPassword']}
            </HelperText>
          </>
        )}
        {alertProps && (
          <CustomTypography>{alertProps.message}</CustomTypography>
        )}
        <StyledButton
          type='submit'
          disabled={!(formik.isValid && formik.dirty)}
        >
          {submitLabel}
        </StyledButton>
        <StyledText>
          {redirectProps.description}
          <SignUp to={redirectProps.to}>{redirectProps.label}</SignUp>
        </StyledText>
      </StyledForm>
    </StyledPaper>
  );
};

AuthenticationForm.propTypes = {
  signUp: PropTypes.bool,
  submitHandler: PropTypes.func.isRequired,
  userProps: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
    type: PropTypes.string,
    defaultValue: PropTypes.node,
    placeholder: PropTypes.node,
    variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
    color: PropTypes.string,
    error: PropTypes.bool,
    helperText: PropTypes.node,
    label: PropTypes.string,
  }),
  passwordProps: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    defaultValue: PropTypes.node,
    placeholder: PropTypes.node,
    variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
    color: PropTypes.string,
    error: PropTypes.bool,
    helperText: PropTypes.node,
  }),
  confirmPasswordProps: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    defaultValue: PropTypes.node,
    placeholder: PropTypes.node,
    variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
    color: PropTypes.string,
    error: PropTypes.bool,
    helperText: PropTypes.node,
  }),
  submitLabel: PropTypes.node,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  forgetPasswordProps: PropTypes.shape({
    to: PropTypes.string,
    target: PropTypes.string,
    label: PropTypes.node,
  }),
  redirectProps: PropTypes.shape({
    label: PropTypes.string,
    to: PropTypes.string,
    description: PropTypes.string,
  }),
  validationSchema: PropTypes.shape({}),
  alertProps: PropTypes.shape({
    variant: PropTypes.string,
    severity: PropTypes.string,
    message: PropTypes.node,
  }),
};

AuthenticationForm.defaultProps = {
  signUp: false,
  submitLabel: 'Submit',
  title: 'Login',
  userProps: {},
  passwordProps: {},
  confirmPasswordProps: {},
  forgetPasswordProps: {},
  validationSchema: {},
  redirectProps: {},
};

export default AuthenticationForm;
