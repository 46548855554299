import { styled } from '@mui/system';

import CustomList from 'ReactKitComponents/Atoms/ListComponents/List/CustomList';
import CustomCollapse from 'ReactKitComponents/Atoms/Collapse/Collapse';

export const StyledCustomList = styled(CustomList)(({ theme }) => ({
  width: '260px',
  '& .MuiIcon-root': {
    fontSize: theme.typography.h3.fontSize,
  },
  position: 'relative',
}));

export const StyledCustomCollapse = styled(CustomCollapse)(({ theme }) => ({
  width: '260px',
  position: 'absolute',
  backgroundColor: theme.palette.primary.contrastText,
}));
