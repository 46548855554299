import { styled } from '@mui/system';

import ListItemAvatar from '@mui/material/ListItemAvatar';

const StyledListItemAvatar = styled(ListItemAvatar)(() => ({
  avatar: {
    width: '24px',
    height: '24px',
  },
  listAvatar: {
    minWidth: 'auto',
    paddingRight: '20px',
    paddingLeft: '8px',
  },
}));

export default StyledListItemAvatar;
